<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito/lista-debito-veicular"
          ><img :src="icBack"
        /></router-link>
        <span>Débito Veicular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Selecione a opção de parcelamento</p>
        <div class="info border">
          <span class="badge">Parcelamento</span>

          <div class="list scroll">
            <div
              v-for="(parcela, index) in parcelas"
              :key="index"
              class="item border"
            >
              <input
                type="checkbox"
                :id="parcela.id"
                :checked="installmentChecked === parcela.id"
                @click="handleCheckboxChange(parcela.id)"
                :value="parcela.value"
              />
              <label
                :for="parcela.id"
                :class="{ selected: installmentChecked === parcela.id }"
                >{{ parcela.label }}</label
              >
              <small>R$ {{ parcela.value }}</small>
            </div>
          </div>
        </div>
        <img :src="next" class="nextStep" @click="handleNextStepClick" />
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// IMAGES
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'

import { timerToReturnToHome } from '../../helper/timer'

export default defineComponent({
  name: 'ParcelasDebitoVeicular',
  components: {
    FooterGlobal,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      totalDebitos: 0,
      installmentChecked: 0,
      installmentCheckedType: '',
    }
  },
  computed: {
    calculatePixValue() {
      // Calcule o valor de "pixValue" aqui com base nos dados do sessionStorage
      const dataParcelas =
        JSON.parse(sessionStorage.getItem('dadosParcelas')) || {}
      const parcelaDebito = dataParcelas.taxes.find(
        (parcela) => parcela.installment === '0',
      )

      if (parcelaDebito) {
        const coeficiente = parseFloat(parcelaDebito.coefficient)
        const totalDebitos = parseFloat(this.totalDebitos)
        const value = (totalDebitos * coeficiente).toFixed(2)

        return value
      }

      return '0.00' // Valor padrão se não houver dados válidos
    },
    parcelas() {
  const dataParcelas = JSON.parse(sessionStorage.getItem('dadosParcelas')) || {};
  const parcelasData = dataParcelas.taxes.filter(
    (parcela) => parseInt(parcela.installment) > 1 && parseInt(parcela.installment) <= 18
  );

  return parcelasData.map((parcela) => {
    const coeficiente = parseFloat(parcela.coefficient);
    const totalDebitos = parseFloat(this.totalDebitos);
    const installments = parseInt(parcela.installment);
    const value = ((totalDebitos * coeficiente) / installments).toFixed(2);

    return {
      id: parcela.id,
      label: `Crédito ${parcela.installment}x`,
      value: value,
    };
  });
},
  },
  methods: {
    handleCheckboxChange(id) {
      if (this.installmentChecked === id) {
        this.installmentChecked = 0
      } else {
        this.installmentChecked = id
      }
    },

    handleNextStepClick() {
      if (this.installmentChecked === 0) {
        alert('Selecione uma opção para prosseguir')
        return
      }

      // Salvar o valor correspondente ao label selecionado em sessionStorage em caixa alta
      const selectedOption = this.parcelas.find(
        (parcela) => parcela.id === this.installmentChecked,
      )
      if (selectedOption) {
        const selectedValueInUpperCase = selectedOption.label.toUpperCase()
        sessionStorage.setItem(
          'selectedInstallmentValue',
          selectedValueInUpperCase,
        )
      }

      if (this.installmentChecked === 'pix') {
        this.$router.push('/debito/pagamento-debito-veicular-pix')
      } else {
        sessionStorage.setItem('installmentSelect', this.installmentChecked)
        this.$router.push('/debito/pagamento-debito-veicular-cartao')
      }
    },
  },
  created() {
    const dataDebito = JSON.parse(sessionStorage.getItem('totalDebito')) || {}
    this.totalDebitos = dataDebito.totalDebitos || 0
  },
})
</script>
