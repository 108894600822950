<template>
	<div class="navigation">
		<span></span>
		<router-link to="/home"><img :src="icClose"></router-link>
    </div>
</template>
<script>
	import { defineComponent } from 'vue';
	import icBack from '@/assets/svg/ic-back.svg';
    import icClose from '@/assets/svg/ic-close.svg';

	export default defineComponent({
		name: 'NavigationComponent',
		data() {
			return {
				icBack: icBack, 
                icClose: icClose, 
				showSpan: false, // Inicialmente, a variável showSpan será false
			};
		},
	})
</script>