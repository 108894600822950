import axios from 'axios'

const saveTransaction = async function (purchase) {
  const apiUrl = 'https://appws.feelbank.com.br/cdx/totem/cadastrarTransacao.php';
  const brlCoin = "957"
  const cardNumber = purchase.cardNumber ? purchase.cardNumber.split("*") : null
  const firstDigits = cardNumber ? cardNumber[0] : null
  const lastDigits = cardNumber ? cardNumber[(cardNumber.length - 1)] : null

  let statusToSend = ''
  let code = ''
  let reason = ''
  if (purchase.status == 'approved') {
    statusToSend = 'APROVADA'
    code = '00'
    reason = ''
  } else {
    const reasonData = purchase.statusReason.split('-')
    statusToSend = 'REJEITADA'
    code = reasonData[0]
    reason = reasonData[1]
  }

  const installmentSelect = sessionStorage.getItem('installmentSelect');
  const parcelas = !isNaN(installmentSelect) ? parseInt(installmentSelect, 10) : purchase.installments;
  const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'));

  const payloadData = {
    servico: "Boleto Parcelado",
    ec: purchase.id,
    documento: purchase.document,
    clienteTelefone: dadosPessoais.phone,
    clienteDocumento: dadosPessoais.cpf,
    situacao: statusToSend,
    codigoRetorno: code,
    refuseReason: reason,
    codigoMCC: purchase.mcc,
    autorizacao: purchase.authorizationCode,
    moeda: brlCoin,
    tefNsu: purchase.tefNsu,
    nsu: purchase.nsu,
    valor: purchase.totalAmount,
    valorOriginal: purchase.originalAmount,
    parcelas: parcelas,
    cartaoPortador: purchase.cardHolder,
    cartaoPrimeirosDigitos: firstDigits,
    cartaoUltimosDigitos: lastDigits,
    bandeira: purchase.cardBrand,
    formaPagamento: purchase.type == 'credit-card' ? "CREDITO" : "DEBITO",
    formaCaptura: "pinpad",
    origem: 'TOTEM',
    numeroSerie: purchase.serialNumber
  };
  const headers = {
    'adgToken': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
    'Content-Type': 'application/json'
  };

  return axios.post(apiUrl, payloadData, { headers })
}

// const startTransaction = async function (payload) {
//     var data = JSON.stringify(payload)
//     var config = {
//         method: 'post',
//         url: 'http://192.168.3.123:3000/start-transaction',
//         headers: { 
//             'Authorization': '123',
//             'Content-Type': 'application/json'
//         },
//         data : data
//     }
//     return await axios(config)
//         .then(function (response) {
//             const purchase = response.data
//             saveTransaction(purchase)
//             return purchase
//         })
//         .catch(function (error) {
//             openSupportTask('Erro ao efetuar pagamento no Pinpad', error)
//         })
// }

export { saveTransaction }
