<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito/dados-debito-veicular"><img :src="icBack"></router-link>
        <span>Débito Veicular</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <p>Por favor,
          <small>Confirme seus dados.</small>
        </p>
        <div class="info border">
          <span class="badge">Lista de Débitos</span>

          <div class="list">
            <ul>
              <li><b>Nome:</b> {{ nome }}</li>
              <li><b>Documento:</b> {{ cpf }}</li>
              <li><b>Valor do débito:</b> R$ {{ totalDebitos }}</li>
            </ul>
            <div class="total bg">
              <p>Total dos débitos: <small>R$ {{ totalDebitos }}</small></p>
            </div>
          </div>
        </div>
        <a @click="avançar"><img
            :src="next"
            class="nextStep"
          ></a>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';
import axios from 'axios';

import { timerToReturnToHome } from '@/helper/timer'


export default defineComponent({
  name: 'ListaDebitoVeicular',
  components: {
    FooterGlobal
  },
  data () {
    timerToReturnToHome(40000)

    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      nome: "",
      cpf: "",
      phone: "",
      totalDebitos: 0 // Inicializar com valor padrão

    };
  },
  methods: {
    async avançar () {
      this.loading = true;
      try {
        await this.buscaPlanoDebitoVeicularPorEstado();
        this.$router.push('/debito/parcela-debito-veicular');
      } catch (error) {
        console.error("Erro ao tentar avançar:", error);
      } finally {
        this.loading = false;
      }
    },
    async buscaPlanoDebitoVeicularPorEstado () {

      // Obter o valor armazenado na sessão
      const clienteVeiculo = sessionStorage.getItem('clienteVeiculo');

      const endPoint = "https://appws.feelbank.com.br/cdx/ws/buscaPlanoDebitoVeicularPorEstado.php";

      const dadosEnvio = {
        estado: clienteVeiculo,
        codParceiro: 2
      };
      try {
        const response = await axios.get(endPoint, {
          params: dadosEnvio,
          headers: {
            'AdgToken': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
            'Content-Type': 'application/json'
          }
        });

        console.log('Dados recebidos:', response.data);

        sessionStorage.setItem('dadosParcelas', JSON.stringify(response.data));

      } catch (error) {
        console.error('Erro ao buscar plano:', error);
      }
    },
  },
  created () {
    const dataResult = JSON.parse(sessionStorage.getItem('dadosPessoais')) || {};
    const dataDebito = JSON.parse(sessionStorage.getItem('totalDebito')) || {};

    this.cpf = dataResult.cpf || "CPF não disponível";
    this.phone = dataResult.phone || "Telefone não disponível";
    this.totalDebitos = dataDebito.totalDebitos || 0;
    this.nome = sessionStorage.getItem('clienteNome') || "Nome não disponível";
  }
})
</script>