<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/recarga/parcela-recarga"><img :src="icBack"></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <div class="timer">
          <p>Tempo restante: 2:59</p>
        </div>
        <p>Pagamento via Pix
          <small>Escaneie o QR Code ou digite o código na sua área PIX. Este código é válido por até 3 minutos.</small>
        </p>

        <div class="pix">
          <span class="badge">PIX</span>
          <div class="qrcode">
            <img :src="qrcode">
          </div>
          <small>Escaneie o QR Code com a câmera do seu <br> celular e efetue o pagamento.</small>
        </div>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icQrcode from '@/assets/svg/qrcode.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';

export default defineComponent({
  name: 'PagamentoBoletoPix',
  components: {
    FooterGlobal
  },
  data () {
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      qrcode: icQrcode,
      selectedOptions: [],
    };
  },
});
</script>