<template>
  <div class="main Seguros">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-gif"></div>
    </div>
    <div class="wrapper">
      <div class="navigation blue">
        <router-link to="/home"><img :src="icBack" /></router-link>
        <span>Seguros e Assistência</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <div class="itens">
          <router-link
            to="seguros/saude-assist-individual"
            @click="saveServiceName('Saúde Assist Individual')"
          >
            <div class="box seguro">
              <img :src="icSaudeAssistIndividual" />
              <p>Saúde Assist Individual</p>
            </div>
          </router-link>

          <router-link
            to="seguros/saude-assist-familiar"
            @click="saveServiceName('Saúde Assist Familiar')"
          >
            <div class="box seguro">
              <img :src="icSaudeAssistFamiliar" />
              <p>Saúde Assist <br />Familiar</p>
            </div>
          </router-link>
        </div>

        <div class="itens">
          <router-link
            to="seguros/auto-assist"
            @click="saveServiceName('Seguro Auto Assist')"
          >
            <div class="box seguro">
              <img :src="icAutoAssist" />
              <p>Auto Assist</p>
            </div>
          </router-link>

          <router-link
            to="seguros/moto-assist"
            @click="saveServiceName('Seguro Moto Assist')"
          >
            <div class="box seguro">
              <img :src="icMotoAssist" />
              <p>Moto Assist</p>
            </div>
          </router-link>
        </div>

        <div class="itens">
          <router-link
            to="seguros/pet-assist"
            @click="saveServiceName('Seguro Pet Assist')"
          >
            <div class="box seguro">
              <img :src="icPetAssist" />
              <p>Pet Assist</p>
            </div>
          </router-link>

          <router-link
            to="seguros/lar-assist"
            @click="saveServiceName('Seguro Lar Assist')"
          >
            <div class="box seguro">
              <img :src="icLarAssist" />
              <p>Lar Assist</p>
            </div>
          </router-link>
        </div>
      </div>
      <FooterCustom></FooterCustom>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next.svg'
import FooterCustom from '@/components/FooterCustom.vue'

// ICONS
import icSaudeAssistIndividual from '@/assets/svg/ic-saude-assist-familiar.svg'
import icSaudeAssistFamiliar from '@/assets/svg/ic-saude-assist-individual.svg'
import icAutoAssist from '@/assets/svg/ic-auto-assist.svg'
import icMotoAssist from '@/assets/svg/ic-moto-assist.svg'
import icPetAssist from '@/assets/svg/ic-pet-assist.svg'
import icLarAssist from '@/assets/svg/ic-lar-assist.png'

export default defineComponent({
  name: 'SegurosPage',
  components: {
    FooterCustom,
  },
  data() {
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      icSaudeAssistIndividual: icSaudeAssistIndividual,
      icSaudeAssistFamiliar: icSaudeAssistFamiliar,
      icAutoAssist: icAutoAssist,
      icMotoAssist: icMotoAssist,
      icPetAssist: icPetAssist,
      icLarAssist: icLarAssist,
    }
  },
  methods: {
    saveServiceName(serviceName) {
      // Salve o nome do serviço em sessionStorage
      sessionStorage.setItem('servicoSelecionado', serviceName)
    },
  },
})
</script>
