<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/boleto/lista-boleto"
          ><img :src="icBack"
        /></router-link>
        <span>Boletos</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Selecione a opção de parcelamento</p>
        <div class="info border">
          <span class="badge">Parcelamento</span>

          <div class="list scroll">
            <div
              v-for="option in creditOptions"
              :key="option.id"
              class="item border parcela"
            >
              <input
                type="checkbox"
                :id="option.id"
                :checked="installmentChecked.id === option.id"
                @change="handleCheckboxChange(option)"
                :value="option.value"
                v-if="option.id > 0"
              />
              <label
                :for="option.id"
                :class="{ selected: selectedOptions.includes(option.id) }"
                v-if="option.id > 0"
                >{{ option.label }}</label
              >
              <!-- Exiba o valor de totalParcela aqui -->
              <small v-if="option.id > 0">R$ {{ option.totalParcela }}</small>
            </div>
          </div>
        </div>
        <img :src="next" class="nextStep" @click="handleNextStepClick" />
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'
import FooterGlobal from '@/components/Footer.vue'
import axios from 'axios'
//import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'ParcelaBoleto',
  components: {
    FooterGlobal,
  },
  data() {
    //timerToReturnToHome(40000)
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      selectedOptions: [],
      installmentChecked: {},
      creditOptions: [],
      sessionStorageData: JSON.parse(
        sessionStorage.getItem('dadosBoleto') || '{}',
      ), // Substitua 'nome_do_seu_objeto' pelo nome correto do item no sessionStorage.
    }
  },
  methods: {
    async fetchParcelOptions() {
      try {
        const headers = {
          AdgToken:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8', // Atualize com seu token
        }

        const response = await axios.post(
          'https://appws.feelbank.com.br/cdx/totem/tabelaBoleto',
          {
            valor: this.sessionStorageData.originalAmount / 100,
          },
          {
            headers: headers,
          },
        )

        if (response.data.status === true) {
          // Atualize as opções de parcelamento com base na resposta
          this.creditOptions = response.data.dados.map((option) => ({
            id: option.numParcelas,
            label: `Crédito ${option.numParcelas}x`,
            value: `Crédito ${option.numParcelas}x`,
            installments: option.numParcelas,
            totalParcela: option.totalParcela.toFixed(2), // Adicione totalParcela à estrutura de dados
          }))
        } else {
          console.error('Erro na resposta do servidor')
        }
      } catch (error) {
        console.error('Erro ao fazer a solicitação HTTP', error)
      }
    },
    getCreditValue() {
      return (this.sessionStorageData.originalAmount / 100).toFixed(2)
    },

    handleCheckboxChange(option) {
      this.installmentChecked = option
    },

    handleNextStepClick() {
      if (!this.installmentChecked.id || this.installmentChecked.id === 0) {
        alert('Selecione uma opção para prosseguir')
        return
      }

      // Salvar a quantidade de parcelas selecionada em sessionStorage
      sessionStorage.setItem('selectedInstallments', this.installmentChecked.id)

      // Salvar o valor correspondente ao label selecionado em sessionStorage em caixa alta
      const selectedOption = this.creditOptions.find(
        (option) => option.id === this.installmentChecked.id,
      )
      if (selectedOption) {
        const selectedValueInUpperCase = selectedOption.label.toUpperCase()
        sessionStorage.setItem(
          'selectedInstallmentValue',
          selectedValueInUpperCase,
        )
      }

      if (this.installmentChecked.value === 'pix') {
        this.$router.push('/debito/pagamento-debito-veicular-pix')
      } else {
        sessionStorage.setItem(
          'installmentSelect',
          JSON.stringify(this.installmentChecked),
        )
        this.$router.push('/boleto/pagamento-boleto-cartao')
      }
    },
  },
  mounted() {
    // Chame a função para buscar as opções de parcelamento quando o componente for montado
    this.fetchParcelOptions()
  },
})
</script>
