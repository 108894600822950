<template>
  <div class="main bgRejeitado">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito/dados-debito-veicular"
          ><img :src="icBack"
        /></router-link>
        <span class="text-white">Débito Veicular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <div class="o-circle c-container__circle o-circle__sign--failure">
          <div class="o-circle__sign"></div>
        </div>
        <h1>Infelizmente o seu pagamento não foi efetuado.</h1>
        <p class="danger">
          Se tiver alguma dúvida, entre em contato conosco: (11) 3003-4996 ou
          aponte a câmera do celular para o QRCode abaixo:
        </p>
        <div v-if="qrCodeData">
          <qrcode :value="qrCodeData" class="successIcon" />
        </div>
        <router-link to="/home"
          ><button class="danger">Voltar</button></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import icClose from '@/assets/svg/ic-close-white.svg'
import { timerToReturnToHome } from '../../helper/timer'
import VueQRCodeComponent from 'vue-qrcode'
import { intToRealBRL } from '@/helper/coin'
import { format } from 'date-fns'
import axios from 'axios'

export default defineComponent({
  name: 'PagamentoDebitoVeicularRejeitada',
  components: {
    qrcode: VueQRCodeComponent,
  },
  data() {
    timerToReturnToHome(40000)
    let purchase = this.$store.getters.receipt
    let msg =
      'Olá, estou com um problema com o pagamento em um Totem HPAY, poderia me ajudar? Número do pedido: ' +
      purchase.orderNumber
    let qrCodeData = 'https://wa.me/1130034996?text=' + msg
    return {
      icClose: icClose,
      qrCodeData,
    }
  },
  methods: {
    formatAmount(amount) {
      return intToRealBRL(amount)
    },
    titleCase(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
    },
    enviarParaAPI() {
      const purchase = this.$store.getters.receipt
      const formattedDate = format(new Date(), 'dd/MM/yy HH:mm:ss')
      const formattedValue = intToRealBRL(purchase.originalAmount)
      const clienteVeiculo = sessionStorage.getItem('clienteVeiculo')
      const placaDigitada = sessionStorage.getItem('placaDigitada')
      const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'))
      const creditoParcela = sessionStorage.getItem('selectedInstallmentValue')

      const receiptDetails = `
  CDX EXPRESS
  CNPJ: 28.707.687/0001-19
  CDX*EXPRESS
  ------------------------------------------------------------------------------
  DATA: ${formattedDate}
  AUT: ${purchase.authorizationCode}  DOC: ${purchase.document}
  EC: ${purchase.mcc} TERM: ${purchase.serialNumber}
  ${purchase.cardBrand} ${purchase.cardNumber}
  ${creditoParcela || ''}
  VALOR: ${formattedValue}
  ------------------------------------------------------------------------------
  TRANSAÇÃO APROVADA 
  MEDIANTE USO DE SENHA PESSOAL
  DEBITO VEICULAR
  ------------------------------------------------------------------------------
  CONFIRA SEUS DADOS
  CPF: ${dadosPessoais.cpf || ''}
  FONE: ${dadosPessoais.phone || ''}
  PLACA: ${placaDigitada || ''}
  ESTADO: ${clienteVeiculo || ''}
  ------------------------------------------------------------------------------
  EM CASO DE DUVIDAS 
  ENTRE EM CONTATO (11) 3541-3001
  `
      const dataToSend = {
        Param: '',
        Data: '',
        ModeloImpressora: 'Default',
        Documents: [receiptDetails],
      }

      axios
        .post('http://localhost:5001/api/print', dataToSend)
        .then((response) => {
          console.log('Resposta da API:', response.data)
        })
        .catch((error) => {
          console.error('Erro ao enviar para a API:', error)
        })
    },
  },
})
</script>
