<template>
  <div class="contentDashboard">
    <input type="file" @change="handleFileChange">
    <button @click="trocarImagem">Trocar</button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DashboardPage',
  data() {
    return {
      name: 'HPAY',
      selectedImage: null,
    };
  },
  methods: {
    
  }
});
</script>
