<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/boleto"><img :src="icBack" /></router-link>
        <span>Boletos</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <ul>
          <li v-if="dadosBoleto.payer">
            <b>Nome:</b> {{ dadosBoleto.payer.name }}
          </li>
          <li v-if="dadosBoleto.payer">
            <b>CPF/CNPJ:</b> {{ dadosBoleto.payer.document }}
          </li>
          <li>
            <b>Valor do boleto:</b>
            {{ formatCurrency(dadosBoleto.originalAmount) }}
          </li>
          <li><b>Juros:</b> {{ formatCurrency(dadosBoleto.interest) }}</li>
          <li><b>Multa:</b> {{ formatCurrency(dadosBoleto.fine) }}</li>
          <li><b>Desconto:</b> {{ formatCurrency(dadosBoleto.discount) }}</li>
          <li>
            <b>Total Boleto:</b> {{ formatCurrency(dadosBoleto.totalAmount) }}
          </li>
          <li>
            <b>Data de vencimento:</b> {{ formatDate(dadosBoleto.dueDate) }}
          </li>
        </ul>
        <router-link to="/boleto/dados-boleto"
          ><img :src="next" class="nextStep"
        /></router-link>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// IMAGES
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'

import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'InfoBoleto',
  components: {
    FooterGlobal,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      dadosBoleto: {},
      total: 0,
    }
  },
  mounted() {
    this.dadosBoleto = JSON.parse(sessionStorage.getItem('dadosBoleto'))
    console.log(this.dadosBoleto)

    if (this.dadosBoleto) {
      // Salvar os dados individualmente no sessionStorage
      if (this.dadosBoleto.payer) {
        sessionStorage.setItem('nome', this.dadosBoleto.payer.name)
        sessionStorage.setItem('cpfCnpj', this.dadosBoleto.payer.document)
      }
      sessionStorage.setItem(
        'valorBoleto',
        this.formatCurrency(this.dadosBoleto.originalAmount),
      )
      sessionStorage.setItem(
        'juros',
        this.formatCurrency(this.dadosBoleto.interest),
      )
      sessionStorage.setItem(
        'multa',
        this.formatCurrency(this.dadosBoleto.fine),
      )
      sessionStorage.setItem(
        'desconto',
        this.formatCurrency(this.dadosBoleto.discount),
      )
      sessionStorage.setItem(
        'totalBoleto',
        this.formatCurrency(this.dadosBoleto.totalAmount),
      )
      sessionStorage.setItem(
        'dataVencimento',
        this.formatDate(this.dadosBoleto.dueDate),
      )
    }
  },
  methods: {
    calculateTotal() {
      return this.items.reduce((accumulator, item) => {
        if (item.selected) {
          return accumulator + item.value
        }
        return accumulator
      }, 0)
    },
    formatCurrency(amountInCents) {
      // Converter centavos para reais
      const amountInReal = amountInCents / 100

      // Use a função Intl.NumberFormat para formatar como moeda brasileira (BRL)
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      })

      return formatter.format(amountInReal)
    },
    formatDate(isoDate) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const date = new Date(isoDate)
      return date.toLocaleDateString('pt-BR', options)
    },
  },
})
</script>
