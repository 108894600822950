<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/home"><img :src="icBack" /></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Qual número de celular deseja recarregar?</p>
        <input
          type="input"
          placeholder="(DD) 00000-0000"
          v-model.lazy="licensePlate"
          @click="toggleKeyboard"
          :class="{ disabled: isInputDisabled }"
          maxlength="11"
        />
        <select v-if="!operadoras.length" class="form-select">
          <option>Carregando...</option>
        </select>
        <select v-else @change="handleChangeOperadora" v-model="linkOperadora" class="form-select">
          <option value="">Selecione a Operadora</option>
          <option
            :value="operadora.link"
            v-for="(operadora, idx) in operadoras"
            :key="idx"
          >
            {{ operadora.operadora }}
          </option>
        </select>
        <virtual-keyboard
          v-if="isKeyboardOpen"
          @key-click="handleKeyClick"
          @close-keyboard="closeKeyboard"
          @delete-click="handleDeleteClick"
        ></virtual-keyboard>
        <router-link to="/recarga/valor-recarga">
          <img
            :src="next"
            class="nextStep"
            :class="{ disabled: isAvancarDisabled }"
          />
        </router-link>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next.svg'
import FooterGlobal from '@/components/Footer.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue' // Import the Keyboard component
import axios from 'axios'

export default defineComponent({
  name: 'BoletoPage',
  components: {
    VirtualKeyboard,
    FooterGlobal,
  },
  data() {
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      linkOperadora: '',
      operadoras: [],
      activeInput: '',
      licensePlate: '',
      isKeyboardOpen: false,
      isInputDisabled: false,
      isAvancarDisabled: true,
      selectedOperadora: '',
    }
  },
  mounted() {
    this.listarOperadoras()
  },
  watch: {
    licensePlate() {
      this.formatPhone()
    },
  },

  methods: {
    handleKeyClick(key) {
      const unformattedPhone = this.licensePlate.replace(/\D/g, '') // Remove non-numeric characters

      if (key === 'backspace') {
        this.licensePlate = this.licensePlate.slice(0, -1)
      } else {
        if (unformattedPhone.length < 11) {
          // permitir até 11 dígitos
          this.licensePlate += key
        }
      }

      this.checkFormCompletion()
    },

    checkFormCompletion() {
      const unformattedPhone = this.licensePlate.replace(/\D/g, '') // Remove non-numeric characters

      if (unformattedPhone.length === 11 && this.selectedOperadora) {
        this.isAvancarDisabled = false // Habilita o botão "nextStep"
      } else {
        this.isAvancarDisabled = true // Desabilita o botão "nextStep"
      }
    },

    handleDeleteClick() {
      if (this.licensePlate.length > 0) {
        this.licensePlate = this.licensePlate.slice(0, -1)
      }
      this.checkFormCompletion()
    },

    toggleKeyboard() {
      this.isKeyboardOpen = !this.isKeyboardOpen
      this.isInputDisabled = this.isKeyboardOpen
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
      this.isInputDisabled = false
    },
    formatPhone() {
      const unformattedPhone = this.licensePlate.replace(/\D/g, '') // Remove non-numeric characters

      if (unformattedPhone.length <= 11) {
        // Formata o telefone
        const formattedPhone = unformattedPhone.replace(
          /(\d{2})(\d{5})(\d{4})/,
          '($1) $2-$3',
        )
        this.licensePlate = formattedPhone

        // Extrai o DDD e salva na sessão
        const ddd = unformattedPhone.substring(0, 2)
        sessionStorage.setItem('ddd', ddd)

        // Salva o número de telefone sem o DDD na sessão
        const phoneNumberWithoutDdd = unformattedPhone.substring(2)
        sessionStorage.setItem('phoneRecarga', phoneNumberWithoutDdd)
      }
    },

    handleChangeOperadora() {
      sessionStorage.setItem('linkOperadora', this.linkOperadora)
      this.selectedOperadora = this.linkOperadora
      this.checkFormCompletion()

      const selectedOption = this.operadoras.find(operadora => operadora.link === this.linkOperadora);

      if (selectedOption) {
        const selectedText = selectedOption.operadora;
        sessionStorage.setItem('operadoraSelecionada', selectedText);
        this.selectedOperadora = selectedText;
        this.checkFormCompletion();
      }
    },
    async listarOperadoras() {
      let response = await axios.post(
        'https://appws.feelbank.com.br/cdx/totem/recargaListaOperadoras',
        {},
        {
          headers: {
            AdgToken:
              'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
          },
        },
      )

      if (response.data.status) {
        this.operadoras = response.data.dados
      }
    },
  },
})
</script>
