<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/recarga/dados-recarga"
          ><img :src="icBack"
        /></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Selecione a opção de parcelamento</p>
        <div class="info border">
          <span class="badge">Parcelamento</span>

          <div class="list scroll">
            <div
              v-for="(option, idx) in creditOptions"
              :key="idx"
              class="item border"
            >
              <input
                type="checkbox"
                :id="idx"
                v-model="selectedOption"
                @change="handleChangeCheckbox(idx, option)"
                :value="option.valorParcela"
                :checked="selectedOption === idx"
              />
              <label :for="idx"
                >{{ option.numParcelas === '0' ? 'Débito' : 'Crédito' }}
                {{
                  option.numParcelas > '0' ? `${option.numParcelas} x` : ''
                }}</label
              >
              <small>{{ formatCurrency(option.valorParcela) }}</small>
            </div>
          </div>
        </div>
        <img
          :src="next"
          class="nextStep"
          :class="{ disabled: isNextButtonDisabled }"
          @click="handleNextStepClick"
        />
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// IMAGES
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'
import { post } from '@/services/requests'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'ParcelaBoleto',
  components: {
    FooterGlobal,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      selectedOption: null,
      calculatedValues: [],
      creditOptions: [],
      valorParcela: 0,
    }
  },
  computed: {
    isNextButtonDisabled() {
      return this.selectedOption === null
    },
  },
  mounted() {
    this.getTabelaRecargaOnline()
  },
  methods: {
    async getTabelaRecargaOnline() {
  let valorRecarga = parseInt(sessionStorage.getItem('valorRecarga'), 10);
  if (isNaN(valorRecarga) || valorRecarga <= 0) {
    console.error('O valor da recarga deve ser um número inteiro maior que 0.');
    return;
  }
  let phoneRecarga = sessionStorage.getItem('phoneRecarga');
  let productId = sessionStorage.getItem('productId');
  let ddd = sessionStorage.getItem('ddd');

  try {
    let recargaResponse = await post('/recargaSolicitar.php', {
      valor: valorRecarga,
      telefone: phoneRecarga,
      productId: productId,
      ddd: ddd,
    });

    if (recargaResponse.data.status) {
      sessionStorage.setItem('idTransacao', recargaResponse.data.dados.id);
      let response = await post('/tabelaSorteOnline.php', {
        valor: valorRecarga,
        telefone: phoneRecarga,
        productId: productId,
        ddd: ddd,
      });

      if (response.data.status) {
        this.creditOptions = response.data.dados;
      }
    } else {
      console.error('Falha na chamada da API recargaSolicitar');
      if (
        recargaResponse.data.reason === 'phone_locked_for_recharge'
      ) {
        // Redirecionamento para outra rota
        this.$router.push('/erro-recarga');
      }
    }
  } catch (error) {
    console.error('Erro na chamada da API', error);
  }
},
    formatCurrency(amountInCents) {
    // Converter centavos para reais
    const amountInReal = amountInCents / 100;

    // Use a função Intl.NumberFormat para formatar como moeda brasileira (BRL)
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });

    return formatter.format(amountInReal);
  },
    handleChangeCheckbox(idx, option) {
      this.selectedOption = idx
      this.valorParcela = option.valorParcela
      let paymentData = {
        service: 'Recarga Celular',
        installment: parseInt(option.numParcelas),
        amount: parseInt(parseFloat(option.totalCompra)),
        type: option.numParcelas === '0' ? 'debit-card' : 'credit-card',
        timeout: 60,
      }
      this.$store.dispatch('setPayment', paymentData)
    },
    handleNextStepClick() {
      this.$router.push('/recarga/pagamento-recarga-cartao')
    },
  },
})
</script>
