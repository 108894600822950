<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/home"><img :src="icBack" /></router-link>
        <span>Pix Cap</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <button @click="enviarParaAPI">Gerar comprovante</button>
    </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import icBack from '@/assets/svg/ic-back.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icNext from '@/assets/svg/ic-next.svg';
import FooterGlobal from '@/components/Footer.vue';

export default defineComponent({
  name: 'PixPage',
  components: {
    FooterGlobal,
  },
  data() {
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
    };
  },
  methods: {
    enviarParaAPI() {
      const dataToSend = {
        Param: '',
        Data: '',
        ModeloImpressora: 'Default',
        Documents: [
          'Teste de impressão',
        ],
      };

      // Enviar os dados para a API usando o Axios
      axios.post('http://localhost:5001/api/print', dataToSend)
        .then(response => {
          console.log('Resposta da API:', response.data);
          // Lógica adicional, se necessário, após receber a resposta da API
        })
        .catch(error => {
          console.error('Erro ao enviar para a API:', error);
          // Tratamento de erro, se necessário
        });
    },
  },
});
</script>