<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/recarga/info-recarga"
          ><img :src="icBack"
        /></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Por favor, <small>Preencha seus dados.</small></p>
        <div class="info">
          <span class="badge">Dados pessoais</span>
          <div class="list">
            <input
              type="text"
              placeholder="CPF"
              v-model="formattedCPF"
              @click="openCPFKeyboard"
              maxlength="14"
            />
          </div>
        </div>
        <virtual-keyboard
          v-if="isKeyboardOpen"
          @key-click="handleKeyClick"
          @close-keyboard="closeKeyboard"
          @delete-click="handleDeleteClick"
        ></virtual-keyboard>
        <router-link to="/recarga/parcela-recarga"
          ><img
            :src="next"
            class="nextStep"
            :class="{ disabled: !isNextStepEnabled }"
        /></router-link>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icNext from '@/assets/svg/ic-next.svg'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import FooterGlobal from '@/components/Footer.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'DadosBoleto',
  components: {
    FooterGlobal,
    VirtualKeyboard,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      next: icNext,
      icBack: icBack,
      icClose: icClose,
      formattedCPF: '',
      isKeyboardOpen: false,
      activeInput: 'cpf', // Mark CPF field as active
      isNextStepEnabled: false,
    }
  },
  watch: {
    formattedCPF() {
      this.checkFieldsFilled()
    },
  },
  methods: {
    checkFieldsFilled() {
      // Remova pontos e traços do CPF e obtenha apenas os dígitos
      const unformattedCPF = this.formattedCPF.replace(/\D/g, '')

      if (unformattedCPF.length === 11) {
        // Verifica se todos os dígitos são iguais
        if (!/(\d)\1{10}/.test(unformattedCPF)) {
          this.isNextStepEnabled = true // Habilita o botão se o CPF for válido
          return
        }
      }

      this.isNextStepEnabled = false // Desabilita o botão se o CPF estiver incompleto ou inválido
    },
    openCPFKeyboard() {
      this.isKeyboardOpen = true
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
    },
    saveDataToSessionStorage() {
      const data = {
        cpf: this.formattedCPF,
      }

      sessionStorage.setItem('dadosPessoais', JSON.stringify(data))
    },

    handleKeyClick(key) {
      if (key === 'backspace') {
        if (this.activeInput === 'cpf' && this.formattedCPF) {
          this.formattedCPF = this.formattedCPF.slice(0, -1)
          this.formatCPF() // Reaplicar formatação do CPF após remover o caractere
        }
      } else {
        if (this.activeInput === 'cpf' && this.formattedCPF.length < 14) {
          const unformattedCPF = this.formattedCPF.replace(/\D/g, '') // Remove caracteres não numéricos
          const newCPF = unformattedCPF + key

          if (newCPF.length <= 11) {
            const cpfGroups = newCPF.match(
              /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/,
            )
            if (cpfGroups) {
              const formattedCPF = cpfGroups
                .slice(1)
                .filter((group) => group !== '')
                .join('.')
              this.formattedCPF = formattedCPF

              // Verificar se o CPF digitado contém uma sequência inválida de 3 números iguais
              const cpfNumbers = newCPF
              if (/(\d)\1{2}/.test(cpfNumbers)) {
                // Exibir o alerta
                alert('Digite um CPF válido')
                // Limpar o campo de CPF
                this.formattedCPF = ''
              }
            }
          }
        }
      }
      this.saveDataToSessionStorage()
    },
    handleDeleteClick() {
      if (this.activeInput === 'cpf' && this.formattedCPF.length > 0) {
        this.formattedCPF = this.formattedCPF.slice(0, -1)
      } else if (
        this.activeInput === 'phone' &&
        this.formattedPhone.length > 0
      ) {
        this.formattedPhone = this.formattedPhone.slice(0, -1)
      }
    },
    formatCPF() {
      const unformattedCPF = this.formattedCPF.replace(/\D/g, '') // Remove non-numeric characters
      if (unformattedCPF.length <= 11) {
        const cpfGroups = unformattedCPF.match(
          /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/,
        )
        if (cpfGroups) {
          const formattedCPF = cpfGroups
            .slice(1)
            .filter((group) => group !== '')
            .join('.')
          this.formattedCPF = formattedCPF
        }
      }
    },
  },
})
</script>
