<template>
  <div class="keyboard" :class="{ 'calculator-style': isNumericKeyboard }">
    <div
      class="key"
      v-for="key in keys.row1"
      :key="`row1-${key}`"
      @click="handleKeyClick(key)"
    >
      {{ key }}
    </div>

    <div
      class="key"
      v-for="key in keys.row2"
      :key="`row2-${key}`"
      @click="handleKeyClick(key)"
    >
      {{ key }}
    </div>

    <div
      class="key"
      v-for="key in keys.row3"
      :key="`row3-${key}`"
      @click="handleKeyClick(key)"
    >
      {{ key }}
    </div>

    <div
      class="key"
      v-for="key in keys.row4"
      :key="`row4-${key}`"
      @click="handleKeyClick(key)"
    >
      {{ key }}
    </div>
    <div class="key large delete" @click="handleDeleteClick">Apagar</div>
    <div class="key large ok" @click="handleDoneClick">OK</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keys: {
        row1: [],
        row2: [],
        row3: [],
        row4: [],
      },
      showDoneButton: false,
      isNumericKeyboard: false,
    };
  },
  mounted() {
    this.setKeysBasedOnRoute();
  },
  methods: {
    setKeysBasedOnRoute() {
      const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
      const letters = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'X', 'C', 'V', 'B', 'N', 'M'];

      if (
        this.$route.path === '/debito/dados-debito-veicular' ||
        this.$route.path.includes('/sorte/dados-sorte') ||
        this.$route.path.includes('/recarga') ||
        this.$route.path.includes('/boleto') ||
        this.$route.path.includes('/seguros/saude-assist-individual/dados') ||
        this.$route.path.includes('/seguros/saude-assist-familiar/dados') ||
        this.$route.path.includes('/seguros/auto-assist/dados') ||
        this.$route.path.includes('/seguros/moto-assist/dados') ||
        this.$route.path.includes('/seguros/pet-assist/dados') ||
        this.$route.path.includes('/seguros/lar-assist/dados') ||
        this.$route.path.includes('/pix')
      ) {
        this.keys.row1 = numbers.slice(0, 10);
        this.keys.row2 = [];
        this.keys.row3 = [];
        this.keys.row4 = [];
        this.isNumericKeyboard = true; // Define o teclado como numérico
      } else {
        this.keys.row1 = numbers.slice(0, 10);
        this.keys.row2 = letters.slice(0, 10);
        this.keys.row3 = letters.slice(10, 19);
        this.keys.row4 = letters.slice(19);
        this.isNumericKeyboard = false; // Define o teclado como não numérico
      }
    },
    handleDeleteClick() {
      this.$emit('delete-click');
    },
    onDeleteClick() {
      this.inputValue = this.inputValue.slice(0, -1);
    },
    handleKeyClick(key) {
      this.$emit('key-click', key);
      this.showDoneButton = true;
    },
    handleDoneClick() {
      this.$emit('close-keyboard');
      this.showDoneButton = false;
    },
  },
};
</script>

<style>
.keyboard {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: fixed;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1;
  background: #f1f1f1;
}

.key {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  cursor: pointer;
  background: #fff;
  font-size: 30px;
  border-radius: 15px;
  border: 1px solid #cecece;
  transition: background-color 0.3s;
}

.key:not(.large):hover {
  background-color: #eaeaea;
}

.key.large {
  grid-column: span 5;
}

.key.ok { 
    background: #1c664a;
    color: #fff;
    font-weight: bold;
}
.key.done {
  background: #007aff;
  color: #fff;
}

.calculator-style {
  width: 50%;
  background: #f1f1f1;
  display: block;
}

.calculator-style .key {
  width: 33.3%;
  height: 110px;
  font-size: 44px !important;
  display: inline-block;
  text-align: center;
  padding-top: 25px;
  border: 1px solid #cecece;
}

.calculator-style .key:not(.large):hover {
  
}

.calculator-style .key.large.delete {
  font-size: 35px !important;
  padding-top: 33px;
  font-weight: bold;
}

.calculator-style .key.large.ok {
  background: #1c664a;
    font-size: 35px !important;
    padding-top: 33px;
    color: #fff;
    font-weight: bold;
}
</style>
