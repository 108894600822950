<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/pix/info-pix"><img :src="icBack"></router-link>
        <span>Pix Cap</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <div class="select-sorte">
          <div class="box-normal">
            <p style="font-size: 40px;"><b>Dados Bancários</b></p>
            <ul>
              <li><b>Nome:</b> Adaga Digital</li>
              <li><b>CPF:</b> 222.333.444</li>
              <li><b>Banco:</b> ITAU UNIBANCO S.A</li>
            </ul>
          </div>

          <div class="box-normal">
            <p style="font-size: 40px;"><b>Endereço</b></p>
            <ul>
              <li><b>Logradouro:</b> Rua Francisco José</li>
              <li><b>Bairro:</b> Vila Andrade</li>
              <li><b>Cidade:</b> São Paulo</li>
              <li><b>Estado:</b> SP</li>
            </ul>
            <div class="inputs" style="display: flex;">
              <input type="input" placeholder="Numero">
              <input type="input" placeholder="Complemento">
            </div>
          </div>
        </div>
        <router-link to="/pix/parcela-pix"><img
            :src="next"
            class="nextStep"
          ></router-link>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
  
  <script>
import { defineComponent } from 'vue';
import icNext from '@/assets/svg/ic-next.svg';
import icBack from '@/assets/svg/ic-back.svg';
import icClose from '@/assets/svg/ic-close.svg';
import FooterGlobal from '@/components/Footer.vue';

export default defineComponent({
  name: 'DadosPix',
  components: {
    FooterGlobal,
  },
  data () {
    return {
      next: icNext,
      icBack: icBack,
      icClose: icClose,
      formattedCPF: '',
      formattedPhone: '',
    };
  },
  watch: {

  },
  methods: {
    formatCPF () {
      const unformattedCPF = this.formattedCPF.replace(/\D/g, ''); // Remove non-numeric characters
      if (unformattedCPF.length <= 11) {
        const cpfGroups = unformattedCPF.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
        if (cpfGroups) {
          const formattedCPF = cpfGroups.slice(1).filter(group => group !== '').join('.');
          this.formattedCPF = formattedCPF;
        }
      }
    },
    formatPhone () {
      const unformattedPhone = this.formattedPhone.replace(/\D/g, ''); // Remove non-numeric characters
      if (unformattedPhone.length <= 11) {
        const phoneGroups = unformattedPhone.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);
        if (phoneGroups) {
          const formattedPhone = phoneGroups
            .slice(1)
            .filter(group => group !== '')
            .map((group, index) => {
              if (index === 0) return `(${group})`;
              if (index === 1) return `${group} `;
              return group;
            })
            .join('');
          this.formattedPhone = formattedPhone;
        }
      }
    },
  }
})
  </script>
