<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito/selecao-debito-veicular"
          ><img :src="icBack"
        /></router-link>
        <span>Débito Veicular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Por favor, <small>Preencha seus dados.</small></p>
        <div class="info">
          <span class="badge">Dados pessoais</span>
          <div class="list">
            <input
              type="text"
              placeholder="CPF"
              v-model="formattedCPF"
              @click="openCPFKeyboard"
            />
            <input
              type="text"
              placeholder="Celular"
              v-model="formattedPhone"
              @click="openPhoneKeyboard"
            />
          </div>
        </div>
        <virtual-keyboard
          v-if="isKeyboardOpen"
          @key-click="handleKeyClick"
          @close-keyboard="closeKeyboard"
          @delete-click="handleDeleteClick"
        ></virtual-keyboard>
        <router-link to="/debito/lista-debito-veicular"
          ><img :src="next" class="nextStep" :class="{ disabled: !formValid }"
        /></router-link>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icNext from '@/assets/svg/ic-next.svg'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import FooterGlobal from '@/components/Footer.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue' // Import the Keyboard component
import { timerToReturnToHome } from '../../helper/timer'

export default defineComponent({
  name: 'DadosDebitoVeicular',
  components: {
    FooterGlobal,
    VirtualKeyboard,
  },
  data() {
    timerToReturnToHome(40000)

    return {
      next: icNext,
      icBack: icBack,
      icClose: icClose,
      formattedCPF: '',
      formattedPhone: '',
      isKeyboardOpen: false,
      formValid: false,
      activeInput: '', // To identify which input is active
    }
  },
  methods: {
    checkFormValidity() {
      const cpfFilled = this.formattedCPF && this.formattedCPF.length === 14 // Verifica se o CPF está totalmente preenchido
      const phoneFilled =
        this.formattedPhone && this.formattedPhone.length === 14 // Verifica se o telefone está totalmente preenchido
      this.formValid = cpfFilled && phoneFilled // Habilita o botão se ambos os campos estiverem preenchidos corretamente
    },
    openCPFKeyboard() {
      this.isKeyboardOpen = true
      this.activeInput = 'cpf' // Mark CPF field as active
    },
    openPhoneKeyboard() {
      this.isKeyboardOpen = true
      this.activeInput = 'phone' // Mark phone field as active
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
      this.activeInput = '' // Clear active field on keyboard close
    },
    saveDataToSessionStorage() {
      const data = {
        cpf: this.formattedCPF,
        phone: this.formattedPhone,
        // Considerando que o total de débitos é uma propriedade deste componente
        // Se não for o caso, você deve obtê-la de outra forma
        totalDebitos: this.totalDebitos,
      }

      sessionStorage.setItem('dadosPessoais', JSON.stringify(data))
    },

    handleKeyClick(key) {
      if (key === 'backspace') {
        if (this.activeInput === 'cpf' && this.formattedCPF) {
          this.formattedCPF = this.formattedCPF.slice(0, -1)
          this.formatCPF() // Reaplicar formatação do CPF após remover o caractere
        } else if (this.activeInput === 'phone' && this.formattedPhone) {
          // Armazenar o valor antes de remover o caractere
          const previousValue = this.formattedPhone
          // Remover o caractere
          this.formattedPhone = this.formattedPhone.slice(0, -1)
          // Se o caractere removido for um espaço ou um parêntese, remover mais um caractere
          if (
            previousValue.slice(-1) === ' ' ||
            previousValue.slice(-1) === ')'
          ) {
            this.formattedPhone = this.formattedPhone.slice(0, -1)
          }
        }
      } else {
        if (this.activeInput === 'cpf' && this.formattedCPF.length < 14) {
          this.formattedCPF += key
          this.formatCPF() // Reaplicar formatação do CPF após adicionar o caractere
        } else if (
          this.activeInput === 'phone' &&
          this.formattedPhone.length < 14
        ) {
          this.formattedPhone += key
          this.formatPhone() // Reaplicar formatação do telefone após adicionar o caractere
        }
      }
      this.formatCPF() // Apply CPF formatting whenever the value changes
      this.formatPhone() // Apply phone formatting whenever the value changes
      this.checkFormValidity()
      this.saveDataToSessionStorage()
    },
    handleDeleteClick() {
      if (this.activeInput === 'cpf' && this.formattedCPF.length > 0) {
        this.formattedCPF = this.formattedCPF.slice(0, -1)
      } else if (
        this.activeInput === 'phone' &&
        this.formattedPhone.length > 0
      ) {
        this.formattedPhone = this.formattedPhone.slice(0, -1)
      }
    },
    formatCPF() {
      const unformattedCPF = this.formattedCPF.replace(/\D/g, '') // Remove non-numeric characters
      if (unformattedCPF.length <= 11) {
        const cpfGroups = unformattedCPF.match(
          /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/,
        )
        if (cpfGroups) {
          const formattedCPF = cpfGroups
            .slice(1)
            .filter((group) => group !== '')
            .join('.')
          this.formattedCPF = formattedCPF
        }
      }
    },
    formatPhone() {
      const unformattedPhone = this.formattedPhone.replace(/\D/g, '') // Remove non-numeric characters
      if (unformattedPhone.length <= 11) {
        const phoneGroups = unformattedPhone.match(
          /^(\d{0,2})(\d{0,5})(\d{0,4})$/,
        )
        if (phoneGroups) {
          const formattedPhone = phoneGroups
            .slice(1)
            .filter((group) => group !== '')
            .map((group, index) => {
              if (index === 0) return `(${group})`
              if (index === 1) return `${group} `
              return group
            })
            .join('')
          this.formattedPhone = formattedPhone
        }
      }
    },
  },
})
</script>
