import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        receipt: '',
        paymentData: '',
        selectedImage: null
    },
    mutations: {
        setReceipt(state, receipt) {
            state.receipt = receipt
        },
        setOrderId(state, id) {
            state.receipt.orderNumber = id
        },
        setPayment(state, paymentData) {
            state.paymentData = paymentData
        },
        updateImage(state, image) {
            state.selectedImage = image;
          },
    },
    actions: {
        setReceipt({ commit }, receipt) {
            commit('setReceipt', receipt)
        },
        setOrderId({ commit }, id) {
            commit('setOrderId', id)
        },
        setPayment({ commit }, id) {
            commit('setPayment', id)
        }
    },
    getters: {
        receipt(state) {
            return state.receipt
        },
        paymentData(state) {
            return state.paymentData
        }
    }
})
