import axios from 'axios' 

const openSupportTask = function (title, description) {
    var data = JSON.stringify({
        "title": localStorage.getItem('totemId') + ' - ' + title,
        "description": description,
        "tags": [
            "totem",
            "hpay"
        ],
        "priority": "high"
    });
      
    var config = {
        method: 'post',
        url: 'https://ln6alursuc.execute-api.sa-east-1.amazonaws.com/error-report',
        headers: { 
            'Api-Key': '2655EDCF-3E86-4ABB-A3C0-8E15E94D6F99', 
            'Content-Type': 'application/json'
        },
        data : data
    }
    axios(config)
        .then(function (response) {
           console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        })
}
export { openSupportTask }
