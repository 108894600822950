<template>
  <div class="main">
    <div class="wrapper">
      <NavComponent></NavComponent>
        <div class="center">
          <img :src="icSuporte">
          <h2>Central de Atendimento</h2>
          <p>(18) 3203-4510
          <br>
          Segunda a Sexta das 9h às 19h </p>
        </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// COMPONENTS
import NavComponent from '@/components/Nav.vue';
import FooterGlobal from '@/components/Footer.vue';

// IMAGES
import suporte from '@/assets/svg/ic-suporte-bg.svg';
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'SuportePage',
  components: {
      NavComponent,
      FooterGlobal
		},
    data() {
      timerToReturnToHome(40000)
			return {
				// ICONS
				icSuporte: suporte, 
			};
		},
})
</script>