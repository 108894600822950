<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/pix/dados-pix"><img :src="icBack"></router-link>
        <span>Pix Cap</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <p>Selecione a opção de parcelamento</p>
        <div class="info border">
          <span class="badge">Parcelamento</span>

          <div class="list scroll">
            <div
              v-for="option in creditOptions"
              :key="option.id"
              class="item border"
            >
              <input
                type="checkbox"
                :id="option.id"
                v-model="selectedOptions"
                @change="handleCheckboxChange(option.id)"
                :value="option.value"
              >
              <label
                :for="option.id"
                :class="{ selected: selectedOptions.includes(option.id) }"
              >{{ option.label }}</label>
              <small>{{ option.value === 'PIX' ? 'R$ 0,00' : `R$ ${getCreditValue(option.value)}` }}</small>
            </div>
          </div>
        </div>
        <img
          :src="next"
          class="nextStep"
          @click="handleNextStepClick"
        >
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';

export default defineComponent({
  name: 'ParcelasPix',
  components: {
    FooterGlobal
  },
  data () {
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      selectedOptions: [],
      creditOptions: [
        { id: 'credito1x', label: 'Crédito 1x', value: 'Crédito 1x' },
        { id: 'credito2x', label: 'Crédito 2x', value: 'Crédito 2x' },
        { id: 'credito3x', label: 'Crédito 3x', value: 'Crédito 3x' },
        { id: 'credito4x', label: 'Crédito 4x', value: 'Crédito 4x' },
        { id: 'credito5x', label: 'Crédito 5x', value: 'Crédito 5x' },
        { id: 'credito6x', label: 'Crédito 6x', value: 'Crédito 6x' },
        { id: 'credito7x', label: 'Crédito 7x', value: 'Crédito 7x' },
        { id: 'credito8x', label: 'Crédito 8x', value: 'Crédito 8x' },
        { id: 'credito9x', label: 'Crédito 9x', value: 'Crédito 9x' },
        { id: 'credito10x', label: 'Crédito 10x', value: 'Crédito 10x' },
        { id: 'credito11x', label: 'Crédito 11x', value: 'Crédito 11x' },
        { id: 'credito12x', label: 'Crédito 12x', value: 'Crédito 12x' }
      ]
    };
  },
  methods: {
    getCreditValue (optionValue) {
      // Defina os valores correspondentes para cada opção de pagamento com cartão
      const values = {
        'Crédito 1x': '0,00',
        'Crédito 2x': '0,00',
        'Crédito 3x': '0,00',
        'Crédito 4x': '0,00',
        'Crédito 5x': '0,00',
        'Crédito 6x': '0,00',
        'Crédito 7x': '0,00',
        'Crédito 8x': '0,00',
        'Crédito 9x': '0,00',
        'Crédito 10x': '0,00',
        'Crédito 11x': '0,00',
        'Crédito 12x': '0,00'
      };

      return values[optionValue] || '0,00';
    },

    handleCheckboxChange (id) {
      if (this.selectedOptions.includes(id)) {
        const index = this.selectedOptions.indexOf(id);
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(id);
      }
    },
    handleNextStepClick() {
      this.$router.push('/pix/pagamento-pix-sucesso');
    },
  },
})
</script>