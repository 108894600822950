import api from "./api";

const post = (endpoint, data = {}) => {
    return api.post(endpoint, {
        "documento": "43261490000192",
        "ec": "TOTEMHPAY-01",
        ...data
    })
}

const get = (endpoint) => {
    return api.get(endpoint)
}

export { post, get };