<template>
  <div class="main bgSucesso">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/boleto/dados-boleto"
          ><img :src="icBack"
        /></router-link>
        <span class="text-white">Loteria</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <div class="o-circle c-container__circle o-circle__sign--success">
          <div class="o-circle__sign"></div>
        </div>
        <h1>
          Pagamento efetuado <br /> 
          com sucesso!
        </h1>
        <p class="white">
          {{ formatAmount(purchase.totalAmount) }} <br /><br />{{
            titleCase(dateFormatted[0])
          }}<br />
          {{ dateFormatted[1] }} {{ titleCase(dateFormatted[2]) }} <br />
          {{ dateFormatted[3] }}
          <br /><br />
          Nº do pedido: {{ purchase.orderNumber }}
        </p>
        <button @click="enviarParaAPI" class="success" :disabled="isButtonDisabled">
          {{ isButtonDisabled ? 'Gerando Comprovante...' : 'Gerar Comprovante' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import icClose from '@/assets/svg/ic-close-white.svg'
import icSuccess from '@/assets/svg/success.svg'
import { intToRealBRL } from '@/helper/coin'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { timerToReturnToHome } from '@/helper/timer'
import axios from 'axios'

export default defineComponent({
  name: 'PagamentoBoletoSucesso',
  components: {},
  mounted() {
    setTimeout(() => {
      this.isButtonDisabled = false
    }, 5000)
  },
  methods: {
    formatAmount(amount) {
      return intToRealBRL(amount)
    },
    titleCase(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
    },
    enviarParaAPI() {
      const purchase = this.$store.getters.receipt
      const formattedDate = format(new Date(), 'dd/MM/yy HH:mm:ss')
      const formattedValue = intToRealBRL(purchase.originalAmount)
      const concourseFromSession = sessionStorage.getItem('concourse')
      const concourseDateFromSession = sessionStorage.getItem('concourseDate')
      const formattedConcourseDate = concourseDateFromSession
        ? format(new Date(concourseDateFromSession), 'dd/MM/yyyy')
        : ''
      const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'))
      const codigoBolaoFromSession = sessionStorage.getItem('name')
      const paymentTypeCheckbox = sessionStorage.getItem('selectedPaymentType')
      const urlJogoFromSession = sessionStorage.getItem('urlJogo')
      let urlJogo = ''
      if (urlJogoFromSession) {
        // Formatar a URL com quebras de linha
        const parts = urlJogoFromSession.split('/')
        const breakIndex = parts.findIndex((part) => part === 'mega-da-virada')

        if (breakIndex !== -1 && breakIndex < parts.length - 1) {
          const firstPart = parts.slice(0, breakIndex + 1).join('/')
          const secondPart = parts.slice(breakIndex + 1).join('/')
          urlJogo = `${firstPart}/\n${secondPart}`
        } else {
          urlJogo = urlJogoFromSession // Caso não encontre a parte específica na URL
        }
      }

      const receiptDetails = `
  CDX EXPRESS
  CNPJ: 28.707.687/0001-19
  CDX*EXPRESS
  ------------------------------------------------------------------------------
  DATA: ${formattedDate}
  AUT: ${purchase.authorizationCode}  DOC: ${purchase.document}
  EC: ${purchase.mcc} TERM: ${purchase.serialNumber}
  ${purchase.cardBrand} ${purchase.cardNumber}
  ${paymentTypeCheckbox || ''} A VISTA
  VALOR: ${formattedValue}
  ------------------------------------------------------------------------------
  TRANSAÇÃO APROVADA 
  MEDIANTE USO DE SENHA PESSOAL
  SORTE ONLINE
  ------------------------------------------------------------------------------
  CONFIRA SEUS DADOS
  CPF: ${dadosPessoais.cpf || ''}
  FONE: ${dadosPessoais.phone || ''}
  ------------------------------------------------------------------------------
  CONCURSO: ${concourseFromSession || ''}
  DATA DO SORTEIO: ${formattedConcourseDate || ''}
  CODIGO DO BOLÃO: ${codigoBolaoFromSession || ''}
  ------------------------------------------------------------------------------
  EM CASO DE DUVIDAS 
  ENTRE EM CONTATO (11) 3541-3001
  ------------------------------------------------------------------------------
  Acesse o comprovante em:
  ${urlJogo || ''}
  `
      const dataToSend = {
        Param: '',
        Data: '',
        ModeloImpressora: 'Default',
        Documents: [receiptDetails],
      }
      // Enviar os dados para a API usando o Axios
      axios
        .post('http://localhost:5001/api/print', dataToSend)
        .then((response) => {
          console.log('Resposta da API:', response.data)
        })
        .catch((error) => {
      console.error('Erro ao enviar para a API:', error);
      // Exibir um alerta de erro genérico
      alert('Impressora desligada, por favor, tente novamente.');
    });
    },
  },
  data() {
    timerToReturnToHome(40000)
    const date = new Date()
    let dateFormatted = format(
      date,
      "EEEE,'|'d 'de' |MMMM 'de' yyyy '| às' HH'h'mm",
      { locale: ptBR },
    ).split('|')
    let purchase = this.$store.getters.receipt
    return {
      icClose: icClose,
      icSuccess: icSuccess,
      purchase,
      dateFormatted,
      isButtonDisabled: true,
    }
  },
})
</script>
