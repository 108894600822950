import { useRouter } from 'vue-router'
import { onBeforeUnmount } from 'vue';

const timerToReturnToHome = function (time) {
  const router = useRouter()
  let lastAction = new Date().getTime()
  const limitInactiveTime = time

  document.addEventListener("click", function () {
    lastAction = new Date().getTime()
  })

  const si = setInterval(() => {
    const currentTime = new Date().getTime()
    const timeDiff = currentTime - lastAction
    if (timeDiff > limitInactiveTime) {
      router.push('/')
    }
    console.log('contador')
  }, 1000)
  onBeforeUnmount(() => clearInterval(si))
}

export { timerToReturnToHome }