<template>
  <div class="main">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-gif"></div>
    </div>
    <div class="wrapper">
      <h1>Como podemos te ajudar hoje?</h1>
      <div class="itens">
        <router-link to="/debito">
          <div class="box">
            <img :src="icDebitoveicular" />
            <p>Débitos Veiculares <span></span></p>
          </div>
        </router-link>

        <a href="#" onclick="javascript:void(0)">
          <div class="box" @click="avançar()">
            <img :src="icLoteria" class="custom" />
            <p>Jogos de Loteria <span></span></p>
          </div>
        </a>
      </div>

      <div class="itens">
        <router-link to="/boleto">
          <div class="box l2">
            <img :src="icBoleto" />
            <p>Boletos <span></span></p>
          </div>
        </router-link>

        <router-link to="/recarga">
          <div class="box l2">
            <img :src="icRecarga" />
            <p>Recarga de Celular <span></span></p>
          </div>
        </router-link>
      </div>

      <div class="itens">
        <router-link to="">
          <div class="box l3">
            <img :src="icPix" />
            <p>Pix Cap <span>Em breve</span></p>
          </div>
        </router-link>

        <router-link to="/seguros">
          <div class="box l4">
            <img :src="icOutros" />
            <p>Seguro e Assistências <span></span></p>
          </div>
        </router-link>
      </div>
      <router-link to="/suporte"
        ><span
          ><img :src="icSuporte" />Dúvidas? Fale conosco!</span
        ></router-link
      >
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, onBeforeUnmount } from 'vue'
// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'

// IMAGES
import debitoveicular from '@/assets/svg/ic-debitoveicular.svg'
import boleto from '@/assets/svg/ic-boleto.svg'
import pix from '@/assets/svg/ic-pix.svg'
import loteria from '@/assets/svg/ic-loteria.png'
import recarga from '@/assets/svg/ic-recarga.svg'
import outros from '@/assets/svg/ic-outros.svg'
import suporte from '@/assets/svg/ic-suporte.svg'
import { post } from '@/services/requests'
import { version } from '@/helper/vars'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default defineComponent({
  name: 'HomePage',
  // COMPONENTS
  components: {
    FooterGlobal,
  },
  data() {
    onMounted(() => {
      const interval = setInterval(async () => {
        const response = await axios.get('https://hpay.adaga.cc/config/')
        if (response.status === 200) {
          if (response.data.version && response.data.version !== version) {
            window.location.reload(true)
          } else {
            console.log('atualizado')
          }
        }
      }, 60000 * 5)

      onBeforeUnmount(() => clearInterval(interval))
    })
    return {
      // ICONS
      icDebitoveicular: debitoveicular,
      icBoleto: boleto,
      icPix: pix,
      icLoteria: loteria,
      icRecarga: recarga,
      icOutros: outros,
      icSuporte: suporte,
      loading: false,
    }
  },
  methods: {
    async avançar() {
      const router = useRouter()
      this.loading = true
      try {
        await this.jogosSorte()
        this.loading = false
        router.push('/sorte')
      } catch (error) {
        console.error('Erro ao tentar avançar:', error)
      } finally {
        this.loading = false
      }
    },

    async jogosSorte() {
      try {
        let response = await post('/sorteOnlineGetProducts.php')

        if (response.data) {
          sessionStorage.setItem(
            'jogosDisponiveis',
            JSON.stringify(response.data),
          )
          this.$router.push('/sorte')
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
})
</script>
