<template>
  <footer>
    <img :src="logo">
  </footer>
</template>
<script>
import { defineComponent } from 'vue';
import logo from '@/assets/svg/logo2.svg';
import { version } from '@/helper/vars';

export default defineComponent({
  name: 'FooterComponent',
  data() {
    return {
      logo: logo,
      version: version, // Adicione version ao data do componente
      versaoDoTotem: this.getVersaoDoTotem(),
    };
  },
  methods: {
    getVersaoDoTotem() {
      // Obtém a versão do totem do localStorage
      return localStorage.getItem('totemId') || 'Versão não encontrada';
    },
  },
});
</script>