<template>
  <div>
    <!-- Modal -->
    <div id="myModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="fecharModal">&times;</span>
        <h2>Digite o ID do TOTEM</h2>
        <input type="text" v-model="totemId" placeholder="Digite o ID">
        <button @click="salvarTotemId">Salvar</button>
      </div>
    </div>
    <router-view />
  </div>
</template>
<style lang="scss">
@import './styles/global';
</style>
<script>
export default {
  name: 'App',
  data() {
    return {
      totemId: '',
    };
  },
  mounted() {
    // Verifique se há um totemId no localStorage e exiba a modal se não houver
    if (!localStorage.getItem('totemId') || localStorage.getItem('totemId') === 'null') {
      this.mostrarModal();
    }
  },
  methods: {
    mostrarModal() {
      const modal = document.getElementById('myModal');
      modal.style.display = 'block';
    },
    fecharModal() {
      const modal = document.getElementById('myModal');
      modal.style.display = 'none';
    },
    salvarTotemId() {
      localStorage.setItem('totemId', this.totemId);
      this.fecharModal(); // Fechar a modal após salvar o totemId
    },
  },
};

</script>
<style>
#myModal { width: 100%; height: 100vh; align-items: center; }
.modal { display: none; position: fixed; z-index: 10; left: 0; top: 0; width: 100%; height: 100%; overflow: auto; background-color: rgba(0, 0, 0, 0.4); }
.modal-content { background-color: #fff; margin: 15% auto; padding: 40px; width: 50%; text-align: center; border-radius: 20px; position: relative; }
.modal-content h2 { font-size: 35px; margin-bottom: 20px; }
.modal-content input { width: 100%; margin-top: 20px; padding: 20px; font-size: 25px; border-radius: 20px; border: 2px solid #adadad;}
.modal-content button { width: 100%; margin-top: 40px; font-size: 30px; padding: 20px; border: 1px solid transparent; background: #00664A; box-shadow: 0px 15px 14px 0px rgba(0, 0, 0, 0.25); color: #fff; font-weight: bold; border-radius: 20px; }

.close { color: #fff; padding: 12px 30px; border-radius: 50px; font-size: 55px; font-weight: bold; background: #00664A; position: absolute; top: -40px; right: -50px; box-shadow: 0px 15px 14px 0px rgba(0, 0, 0, 0.25); }
.close:hover, .close:focus { color: #fff; text-decoration: none; cursor: pointer; }
</style>
