<template>
  <div class="main SegurosBlue">
    <div class="wrapper">
      <div class="navigation blue">
        <router-link to="/seguros/auto-assist/dados"
          ><img :src="icBack" class="invert"
        /></router-link>
        <span class="text-white">Seguros e Assistência</span>
        <router-link to="/seguros"
          ><img :src="icClose" class="invert"
        /></router-link>
      </div>
      <div class="content">
        <img :src="logoWhite" class="logoWhite" />
        <h4>Pré-cadastro efetuado com sucesso!</h4>
        <img :src="check" class="check" />
        <p class="success">
          Em breve entraremos em contato para finalizar a contração de seu
          plano.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import logoWhite from '@/assets/svg/logo-white.svg'
import check from '@/assets/svg/check.png'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next.svg'

// ICONS
import icSaudeAssistIndividual from '@/assets/svg/ic-saude-assist-familiar.svg'
import icSaudeAssistFamiliar from '@/assets/svg/ic-saude-assist-individual.svg'
import icAutoAssist from '@/assets/svg/ic-auto-assist.svg'
import icMotoAssist from '@/assets/svg/ic-moto-assist.svg'
import icPetAssist from '@/assets/svg/ic-pet-assist.svg'
import icLarAssist from '@/assets/svg/ic-lar-assist.png'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'SegurosPage',
  components: {},
  data() {
    timerToReturnToHome(40000)
    return {
      icBack: icBack,
      icClose: icClose,
      logoWhite: logoWhite,
      check: check,
      next: icNext,
      icSaudeAssistIndividual: icSaudeAssistIndividual,
      icSaudeAssistFamiliar: icSaudeAssistFamiliar,
      icAutoAssist: icAutoAssist,
      icMotoAssist: icMotoAssist,
      icPetAssist: icPetAssist,
      icLarAssist: icLarAssist,
    }
  },
  methods: {},
})
</script>
