<template>
  <div class="main bgSucesso">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/boleto/dados-boleto"><img :src="icBack"></router-link>
        <span class="text-white">Pix Parcelado</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <h1>Pagamento efetuado <br> com sucesso!</h1>
        <img :src="icSuccess" class="successIcon">
        <p class="white">R$00,00

        <br><br>Sexta-feira, <br>
        24 de Março de 2023 <br>
        às 17h48 <br><br>
        Nº do pedido: XKDEI29740KS
      </p>
      <button>Receber comprovante por SMS</button>
      </div>

    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import icClose from '@/assets/svg/ic-close-white.svg';
import icSuccess from '@/assets/svg/success.svg';

export default defineComponent({
  name: 'PagamentoPixSucesso',
  components: {

  },
  data () {
    return {
      icClose: icClose,
      icSuccess: icSuccess,
      selectedOptions: [],
    };
  },
});
</script>