<template>
  <div class="main Seguros">
    <div class="wrapper">
      <div class="navigation blue">
        <router-link to="/seguros"><img :src="icBack" /></router-link>
        <span>Seguros e Assistência</span>
        <router-link to="/seguros"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <h3>Confira abaixo o que está incluso no seu plano:</h3>

        <div class="listServices">
          <div v-for="(item, index) in items" :key="index">
            <div :class="{ item: true }">
              <img :src="item.img" :class="{ black: item.hasBlackClass }" />
              <p>{{ item.title }}</p>
            </div>
            <small>{{ item.description }}</small>
          </div>
        </div>

        <router-link to="auto-assist/dados">
          <img :src="next" class="nextStep" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next-custom.svg'

// ICONS
import icSaudeAssistIndividual from '@/assets/svg/ic-saude-assist-familiar.svg'
import icSaudeAssistFamiliar from '@/assets/svg/ic-saude-assist-individual.svg'
import icAutoAssist from '@/assets/svg/ic-auto-assist.svg'
import icMotoAssist from '@/assets/svg/ic-moto-assist.svg'
import icPetAssist from '@/assets/svg/ic-pet-assist.svg'
import icLarAssist from '@/assets/svg/ic-lar-assist.png'
import { timerToReturnToHome } from '@/helper/timer'
export default defineComponent({
  name: 'SegurosPage',
  components: {},
  data() {
    timerToReturnToHome(40000)
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      icSaudeAssistIndividual: icSaudeAssistIndividual,
      icSaudeAssistFamiliar: icSaudeAssistFamiliar,
      icAutoAssist: icAutoAssist,
      icMotoAssist: icMotoAssist,
      icPetAssist: icPetAssist,
      icLarAssist: icLarAssist,
      isAvancarDisabled: true,

      items: [
        {
          img: 'https://appws.blueassist.com.br/midias/icones/Auto_reboque.png',
          title: 'Reboque',
          description: 'Reboque de até 200km.',
          hasBlackClass: true,
        },

        {
          img: 'https://appws.blueassist.com.br/midias/icones/Auto_reboque.png',
          title: 'Socorro mecânico',
          description: 'Socorro mecânico de até 100km ou R$100,00.',
          hasBlackClass: true,
        },

        {
          img: 'https://appws.blueassist.com.br/midias/icones/Auto_chaveiro.png',
          title: 'Chaveiro',
          description: 'Serviço de chaveiro de até R$200,00.',
          hasBlackClass: true,
        },

        {
          img: 'https://appws.blueassist.com.br/midias/icones/Auto_moto_pneu.png',
          title: 'Troca de pneu',
          description: 'Troca de pneu de até 100km.',
          hasBlackClass: true,
        },

        {
          img: 'https://appws.blueassist.com.br/midias/icones/Auto_moto_pane seca.png',
          title: 'Pane Seca',
          description: 'Pane seca de até 100km.',
          hasBlackClass: true,
        },

        {
          img: 'https://appws.blueassist.com.br/midias/icones/investment.png',
          title: 'Capitalização',
          description: 'Prêmio de R$ 10 mil bruto.',
          hasBlackClass: true,
        },
      ],
      selectedItem: -1,
    }
  },
  methods: {
    selectItem(index) {
      this.selectedItem = index
      this.isAvancarDisabled = false // Habilitar o botão de avançar
      // Salvar o conteúdo do item selecionado no sessionStorage
      sessionStorage.setItem('servico', this.items[index].title)
    },
  },
})
</script>
