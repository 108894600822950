<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/sorte/dados-sorte"><img :src="icBack" /></router-link>
        <p></p>
        <span>Sorte Online</span>
        <p></p>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Selecione a opção de parcelamento</p>
        <div class="info border">
          <span class="badge">Parcelamento</span>

          <div class="list scroll">
            <div
              v-for="(option, idx) in creditOptions"
              :key="idx"
              class="item border"
            >
              <input
                type="checkbox"
                :id="idx"
                v-model="selectedOption"
                @change="handleChangeCheckbox(idx, option)"
                :value="option.valorParcela"
                :checked="selectedOption === idx"
              />
              <label :for="idx"
                >{{ option.numParcelas === '0' ? 'Débito' : 'Crédito' }}
                {{
                  option.numParcelas > '0' ? `${option.numParcelas} x` : ''
                }}</label
              >
              <small>{{ formatarMoeda(option.valorParcela) }}</small>
            </div>
          </div>
        </div>
        <img
          :src="next"
          class="nextStep"
          :class="{ disabled: isNextButtonDisabled }"
          @click="handleNextStepClick"
        />
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// IMAGES
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'
import { post } from '@/services/requests'

import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'ParcelaBoleto',
  components: {
    FooterGlobal,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      selectedOption: null,
      calculatedValues: [],
      creditOptions: [],
      valorParcela: 0,
    }
  },
  computed: {
    isNextButtonDisabled() {
      return this.selectedOption === null
    },
  },
  mounted() {
    this.getTabelaSorteOnline()
  },
  methods: {
    async getTabelaSorteOnline() {
      let priceJogo = sessionStorage.getItem('priceJogo')
      let response = await post('/tabelaSorteOnline.php', { valor: priceJogo })

      if (response.data.status) {
        this.creditOptions = response.data.dados
      }
    },
    formatarMoeda(valor) {
      return `R$ ${parseFloat(valor).toFixed(2).replace('.', ',')}`
    },
    handleChangeCheckbox(idx, option) {
      this.selectedOption = idx
      this.valorParcela = option.valorParcela

      let paymentType = option.numParcelas === '0' ? 'DEBITO' : 'CREDITO'

      sessionStorage.setItem('selectedPaymentType', paymentType)

      let paymentData = {
        service: 'Sorte Online',
        installment: parseInt(option.numParcelas),
        amount: parseInt(parseFloat(option.totalCompra).toFixed(2) * 100),
        type: option.numParcelas === '0' ? 'debit-card' : 'credit-card',
        timeout: 60,
        purchaseType: paymentType, // Definindo o tipo de compra (DEBITO ou CREDITO)
      }

      this.$store.dispatch('setPayment', paymentData)
    },
    handleNextStepClick() {
      this.$router.push('/sorte/pagamento-sorte-cartao')

      // if (this.selectedOptions.length === 0) {
      //   this.$router.push('/sorte/pagamento-sorte-sucesso');
      //   return;
      // }
      // if (this.selectedOptions.includes('pix')) {
      //   this.$router.push('/sorte/pagamento-sorte-pix');
      // } else {
      //   // Redirecionar para a rota de pagamento com cartão
      //   this.$router.push('/sorte/pagamento-sorte-cartao');
      // }
    },
  },
})
</script>
