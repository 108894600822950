<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito/parcela-debito-veicular"><img :src="icBack"></router-link>
        <span>Débito Veicular</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <div class="timer">
          <p>Tempo restante: 2:59</p>
        </div>
        <p>Pagamento via Pix
          <small>Escaneie o QR Code ou digite o código na sua área PIX. Este código é válido por até 3 minutos.</small>
        </p>

        <div class="pix">
          <span class="badge">PIX</span>
          <div class="qrcode">
            <img
              :src="qrcodeJPEGBase64"
              alt="QR Code PIX"
            >

          </div>

          <small>Escaneie o QR Code com a câmera do seu <br> celular e efetue o pagamento.</small>
        </div>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icQrcode from '@/assets/svg/qrcode.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';
import QRCode from 'qrcode';

export default defineComponent({
  name: 'PagamentoDebitoVeicularPix',
  components: {
    FooterGlobal
  },
  data () {
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      qrcode: icQrcode,
      qrcodeJPEGBase64: '',
      totalDebitos: 0,

    };
  },
  methods: {
    async generateQRCode () {
      try {
        const pixKey = 'leeoesouza@gmail.com';
        const merchantName = 'Leonardo de Souza';
        const merchantCity = 'SAO PAULO';

        const payload = `
          000201
          2658
          00BR.GOV.BCB.PIX
          0111${pixKey}
          52040000
          5303986
          5405${this.totalDebitos.toFixed(2)}
          5802BR
          5913${merchantName}
          6008${merchantCity}
          62070503***
          6304AD38
        `.replace(/\s+/g, ''); // Remove espaços e quebras de linha

        this.qrcodeJPEGBase64 = await QRCode.toDataURL(payload);
      } catch (error) {
        console.error('Failed to generate QR Code:', error);
      }
    }
  },
  mounted () {
    this.totalDebitos = parseFloat(sessionStorage.getItem('totalDebito')) || 0;
    this.generateQRCode();
  }
});
</script>