import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/Home";
import Suporte from "@/views/Suporte";

// DEBITO VEICULAR
import Debito from "@/views/debito/Home";
import SelecaoDebitoVeicular from "@/views/debito/SelecaoDebitoVeicular";
import DadosDebitoVeicular from "@/views/debito/DadosDebitoVeicular";
import ListaDebitoVeicular from "@/views/debito/ListaDebitoVeicular";
import ParcelaDebitoVeicular from "@/views/debito/ParcelaDebitoVeicular";
import PagamentoDebitoVeicularPix from "@/views/debito/PagamentoDebitoVeicularPix";
import PagamentoDebitoVeicularCartao from "@/views/debito/PagamentoDebitoVeicularCartao";
import PagamentoDebitoVeicularSucesso from "@/views/debito/PagamentoDebitoVeicularSucesso";
import PagamentoDebitoVeicularRejeitada from "@/views/debito/PagamentoDebitoVeicularRejeitada";
// BOLETO
import Boleto from "@/views/boleto/Home";
import ErroBoleto from "@/views/boleto/Erro";
import InfoBoleto from "@/views/boleto/InfoBoleto";
import DadosBoleto from "@/views/boleto/DadosBoleto";
import ParcelaBoleto from "@/views/boleto/ParcelaBoleto";
import PagamentoBoletoPix from "@/views/boleto/PagamentoBoletoPix";
import PagamentoBoletoCartao from "@/views/boleto/PagamentoBoletoCartao";
import PagamentoBoletoSucesso from "@/views/boleto/PagamentoBoletoSucesso";
import PagamentoBoletoRejeitado from "@/views/boleto/PagamentoBoletoRejeitado";
// PIX
import Pix from "@/views/pix/Home";
import InfoPix from "@/views/pix/InfoPix";
import DadosPix from "@/views/pix/DadosPix";
import ParcelaPix from "@/views/pix/ParcelaPix";
import PagamentoPixCartao from "@/views/pix/PagamentoPixCartao";
import PagamentoPixSucesso from "@/views/pix/PagamentoPixSucesso";
// SORTE
import Sorte from "@/views/sorte/Home";
import DadosSorte from "@/views/sorte/DadosSorte";
import ParcelaSorte from "@/views/sorte/ParcelaSorte";
import PagamentoSorteCartao from "@/views/sorte/PagamentoSorteCartao";
import PagamentoSortePix from "@/views/sorte/PagamentoSortePix";
import PagamentoSorteSucesso from "@/views/sorte/PagamentoSorteSucesso";
import PagamentoSorteRejeitada from "@/views/sorte/PagamentoSorteRejeitada";
// RECARGA
import Recarga from "@/views/recarga/Home";
import ErroRecarga from "@/views/recarga/ErroRecarga";
import ValorRecarga from "@/views/recarga/ValorRecarga";
import InfoRecarga from "@/views/recarga/InfoRecarga";
import DadosRecarga from "@/views/recarga/DadosRecarga";
import ParcelaRecarga from "@/views/recarga/ParcelaRecarga";
import PagamentoRecargaCartao from "@/views/recarga/PagamentoRecargaCartao";
import PagamentoRecargaPix from "@/views/recarga/PagamentoRecargaPix";
import PagamentoRecargaSucesso from "@/views/recarga/PagamentoRecargaSucesso";
import PagamentoRecargaRejeitada from "@/views/recarga/PagamentoRecargaRejeitada";
// SEGUROS
import Seguros from "@/views/seguros/Home";
import SaudeAssistIndividual from "@/views/seguros/saude-assist-individual/Home";
import SaudeAssistIndividualDados from "@/views/seguros/saude-assist-individual/Dados";
import SaudeAssistIndividualSucesso from "@/views/seguros/saude-assist-individual/Sucesso";

import SaudeAssistFamiliar from "@/views/seguros/saude-assist-familiar/Home";
import SaudeAssistFamiliarDados from "@/views/seguros/saude-assist-familiar/Dados";
import SaudeAssistFamiliarSucesso from "@/views/seguros/saude-assist-familiar/Sucesso";

import AutoAssist from "@/views/seguros/auto-assist/Home";
import AutoAssistDados from "@/views/seguros/auto-assist/Dados";
import AutoAssistSucesso from "@/views/seguros/auto-assist/Sucesso";

import MotoAssist from "@/views/seguros/moto-assist/Home";
import MotoAssistDados from "@/views/seguros/moto-assist/Dados";
import MotoAssistSucesso from "@/views/seguros/moto-assist/Sucesso";

import PetAssist from "@/views/seguros/pet-assist/Home";
import PetAssistDados from "@/views/seguros/pet-assist/Dados";
import PetAssistSucesso from "@/views/seguros/pet-assist/Sucesso";

import LarAssist from "@/views/seguros/lar-assist/Home";
import LarAssistDados from "@/views/seguros/lar-assist/Dados";
import LarAssistSucesso from "@/views/seguros/lar-assist/Sucesso";

// ADMIN
import Dashboard from "@/views/admin/Home";


const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/Home",
    children: [
      {
        path: "/admin", name: "Dashboard", component: Dashboard,
      },
      {
        path: "/home", name: "Home", component: Home,
      },
      {
        path: "/suporte", name: "Suporte", component: Suporte,
      },
      {
        path: "/debito", name: "debito", component: Debito,
      },
      {
        path: "/debito/selecao-debito-veicular", name: "selecao-debito-veicular", component: SelecaoDebitoVeicular,
      },
      {
        path: "/debito/dados-debito-veicular", name: "dados-debito-veicular", component: DadosDebitoVeicular,
      },
      {
        path: "/debito/lista-debito-veicular", name: "lista-debito-veicular", component: ListaDebitoVeicular,
      },
      {
        path: "/debito/parcela-debito-veicular", name: "parcela-debito-veicular", component: ParcelaDebitoVeicular,
      },
      {
        path: "/debito/pagamento-debito-veicular-pix", name: "pagamento-debito-veicular-pix", component: PagamentoDebitoVeicularPix,
      },
      {
        path: "/debito/pagamento-debito-veicular-cartao", name: "pagamento-debito-veicular-cartao", component: PagamentoDebitoVeicularCartao,
      },
      {
        path: "/debito/pagamento-debito-veicular-sucesso", name: "pagamento-debito-veicular-sucesso", component: PagamentoDebitoVeicularSucesso,
      },
      {
        path: "/debito/pagamento-debito-veicular-rejeitada", name: "pagamento-debito-veicular-rejeitada", component: PagamentoDebitoVeicularRejeitada,
      },
      {
        path: "/boleto", name: "boleto", component: Boleto,
      },
      {
        path: "/erro", name: "erro", component: ErroBoleto,
      },
      {
        path: "/boleto/info-boleto", name: "info-boleto", component: InfoBoleto,
      },
      {
        path: "/boleto/dados-boleto", name: "dados-boleto", component: DadosBoleto,
      },
      {
        path: "/boleto/parcela-boleto", name: "parcela-boleto", component: ParcelaBoleto,
      },
      {
        path: "/boleto/pagamento-boleto-pix", name: "pagamento-boleto-pix", component: PagamentoBoletoPix,
      },
      {
        path: "/boleto/pagamento-boleto-cartao", name: "pagamento-boleto-cartao", component: PagamentoBoletoCartao,
      },
      {
        path: "/boleto/pagamento-boleto-sucesso", name: "pagamento-boleto-sucesso", component: PagamentoBoletoSucesso,
      },
      {
        path: "/boleto/pagamento-boleto-rejeitado", name: "pagamento-boleto-rejeitado", component: PagamentoBoletoRejeitado,
      },
      {
        path: "/pix", name: "pix", component: Pix,
      },
      {
        path: "/pix/info-pix", name: "info-pix", component: InfoPix,
      },
      {
        path: "/pix/dados-pix", name: "dados-pix", component: DadosPix,
      },
      {
        path: "/pix/parcela-pix", name: "parcela-pix", component: ParcelaPix,
      },
      {
        path: "/pix/pagamento-pix-cartao", name: "pagamento-pix-cartao", component: PagamentoPixCartao,
      },
      {
        path: "/pix/pagamento-pix-sucesso", name: "pagamento-pix-sucesso", component: PagamentoPixSucesso,
      },
      {
        path: "/sorte", name: "sorte", component: Sorte,
      },
      {
        path: "/sorte/dados-sorte", name: "dados-sorte", component: DadosSorte,
      },
      {
        path: "/sorte/parcela-sorte", name: "parcela-sorte", component: ParcelaSorte,
      },
      {
        path: "/sorte/pagamento-sorte-cartao", name: "pagamento-sorte-cartao", component: PagamentoSorteCartao,
      },
      {
        path: "/sorte/pagamento-sorte-pix", name: "pagamento-sorte-pix", component: PagamentoSortePix,
      },
      {
        path: "/sorte/pagamento-sorte-sucesso", name: "pagamento-sorte-sucesso", component: PagamentoSorteSucesso,
      },
      {
        path: "/sorte/pagamento-sorte-rejeitada", name: "pagamento-sorte-rejeitada", component: PagamentoSorteRejeitada,
      },
      {
        path: "/recarga", name: "recarga", component: Recarga,
      },
      {
        path: "/erro-recarga", name: "erro-recarga", component: ErroRecarga,
      },
      {
        path: "/recarga/valor-recarga", name: "valor-recarga", component: ValorRecarga,
      },
      {
        path: "/recarga/info-recarga", name: "info-recarga", component: InfoRecarga,
      },
      {
        path: "/recarga/dados-recarga", name: "dados-recarga", component: DadosRecarga,
      },
      {
        path: "/recarga/parcela-recarga", name: "parcela-recarga", component: ParcelaRecarga,
      },
      {
        path: "/recarga/pagamento-recarga-cartao", name: "pagamento-recarga-cartao", component: PagamentoRecargaCartao,
      },
      {
        path: "/recarga/pagamento-recarga-pix", name: "pagamento-recarga-pix", component: PagamentoRecargaPix,
      },
      {
        path: "/recarga/pagamento-recarga-sucesso", name: "pagamento-recarga-sucesso", component: PagamentoRecargaSucesso,
      },
      {
        path: "/recarga/pagamento-recarga-rejeitada", name: "pagamento-recarga-rejeitada", component: PagamentoRecargaRejeitada,
      },
      {
        path: "/seguros", name: "seguros", component: Seguros,
      },
      {
        path: "/seguros/saude-assist-individual", name: "saude-assist-individual", component: SaudeAssistIndividual,
      },
      {
        path: "/seguros/saude-assist-individual/dados", name: "saude-assist-individual-dados", component: SaudeAssistIndividualDados,
      },
      {
        path: "/seguros/saude-assist-individual/sucesso", name: "saude-assist-individual-sucesso", component: SaudeAssistIndividualSucesso,
      },
      {
        path: "/seguros/saude-assist-familiar", name: "saude-assist-familiar", component: SaudeAssistFamiliar,
      },
      {
        path: "/seguros/saude-assist-familiar/dados", name: "saude-assist-familiar-dados", component: SaudeAssistFamiliarDados,
      },
      {
        path: "/seguros/saude-assist-familiar/sucesso", name: "saude-assist-familiar-sucesso", component: SaudeAssistFamiliarSucesso,
      },
      {
        path: "/seguros/auto-assist", name: "auto-assist", component: AutoAssist,
      },
      {
        path: "/seguros/auto-assist/dados", name: "auto-assist-dados", component: AutoAssistDados,
      },
      {
        path: "/seguros/auto-assist/sucesso", name: "auto-assist-sucesso", component: AutoAssistSucesso,
      },
      {
        path: "/seguros/moto-assist", name: "moto-assist", component: MotoAssist,
      },
      {
        path: "/seguros/moto-assist/dados", name: "moto-assist-dados", component: MotoAssistDados,
      },
      {
        path: "/seguros/moto-assist/sucesso", name: "moto-assist-sucesso", component: MotoAssistSucesso,
      },
      {
        path: "/seguros/pet-assist", name: "pet-assist", component: PetAssist,
      },
      {
        path: "/seguros/pet-assist/dados", name: "pet-assist-dados", component: PetAssistDados,
      },
      {
        path: "/seguros/pet-assist/sucesso", name: "pet-assist-sucesso", component: PetAssistSucesso,
      },
      {
        path: "/seguros/lar-assist", name: "lar-assist", component: LarAssist,
      },
      {
        path: "/seguros/lar-assist/dados", name: "lar-assist-dados", component: LarAssistDados,
      },
      {
        path: "/seguros/lar-assist/sucesso", name: "lar-assist-sucesso", component: LarAssistSucesso,
      },
    ],
  }
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export default router;
