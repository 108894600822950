<template>
  <div class="main Seguros">
    <div class="wrapper">
      <div class="navigation blue">
        <router-link to="/seguros/lar-assist"
          ><img :src="icBack"
        /></router-link>
        <span>Seguros e Assistência</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <h3>Precisamos de algumas informações</h3>
        <p>Insira seu telefone com DDD abaixo:</p>
        <input
          type="input"
          placeholder="(DD) 00000-0000"
          v-model.lazy="licensePlate"
          @click="toggleKeyboard"
          class="custom"
          :class="{ disabledassist: isInputDisabled }"
          maxlength="11"
        />
        <img
          :src="next"
          class="nextStep"
          :class="{ disabled: isAvancarDisabled }"
          @click="sendEmail"
        />
      </div>

      <virtual-keyboard
        v-if="isKeyboardOpen"
        @key-click="handleKeyClick"
        @delete-click="handleDeleteClick"
        @close-keyboard="closeKeyboard"
      ></virtual-keyboard>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next-custom.svg'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import axios from 'axios'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'SegurosPage',
  components: {
    VirtualKeyboard,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      activeInput: '',
      licensePlate: '',
      isKeyboardOpen: false,
      isInputDisabled: false,
      isAvancarDisabled: true,
    }
  },
  watch: {
    licensePlate() {
      this.formatPhone()
    },
  },
  methods: {
    async sendEmail() {
      const currentDateTime = new Date().toLocaleString().replace(', ', '<br>')
      const ddd = sessionStorage.getItem('ddd')
      const phoneNumber = sessionStorage.getItem('phone')
      const selectedService = sessionStorage.getItem('servicoSelecionado')
      const totemName = localStorage.getItem('totemId')

      const fullPhoneNumber = `${ddd}${phoneNumber}`

      const emailData = {
        dataHora: currentDateTime,
        celular: fullPhoneNumber
          .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
          .replace(/(\d{2}) (\d{5})-(\d{4})/, '$1 $2-$3'),
        servico: selectedService.replace(/(.{40})/g, '$1<br>'), // Adiciona quebra de linha a cada 20 caracteres (exemplo)
        totemNome: totemName.replace(/(.{40})/g, '$1<br>'), // Adiciona quebra de linha a cada 20 caracteres (exemplo)
      }

      try {
        console.log('Enviando dados:', emailData) // Adicionando log para os dados a serem enviados

        const headers = {
          headers: {
            AdgToken:
              'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
          },
        }

        const emailEndpoint =
          'https://appws.feelbank.com.br/cdx/totem/envioEmail.php'
        const emailResponse = await axios.post(
          emailEndpoint,
          emailData,
          headers,
        )
        console.log('Resposta do envio de email:', emailResponse.data)

        // Verifica o status da resposta para redirecionar para a tela de sucesso
        if (emailResponse.status === 200) {
          // Aqui você pode redirecionar para a tela de sucesso, por exemplo:
          this.$router.push('/seguros/lar-assist/sucesso')
          console.log(
            'Email enviado com sucesso. Redirecionando para tela de sucesso...',
          )
        }
      } catch (error) {
        console.error('Erro ao enviar o e-mail', error)
      }
    },
    handleKeyClick(key) {
      const unformattedPhone = this.licensePlate.replace(/\D/g, '')

      if (key === 'backspace') {
        this.licensePlate = this.licensePlate.slice(0, -1)
      } else {
        if (unformattedPhone.length < 11) {
          // permitir até 11 dígitos
          this.licensePlate += key
        }
      }

      this.checkFormCompletion()
    },

    checkFormCompletion() {
      const unformattedPhone = this.licensePlate.replace(/\D/g, '')

      if (unformattedPhone.length === 11 && this.licensePlate !== '') {
        this.isAvancarDisabled = false
      } else {
        this.isAvancarDisabled = true
      }
    },

    handleDeleteClick() {
      if (this.licensePlate.length > 0) {
        this.licensePlate = this.licensePlate.slice(0, -1)
      }
      this.checkFormCompletion()
    },

    toggleKeyboard() {
      this.isKeyboardOpen = !this.isKeyboardOpen
      this.isInputDisabled = this.isKeyboardOpen
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
      this.isInputDisabled = false
    },
    formatPhone() {
      const unformattedPhone = this.licensePlate.replace(/\D/g, '')

      if (unformattedPhone.length <= 11) {
        const formattedPhone = unformattedPhone.replace(
          /(\d{2})(\d{5})(\d{4})/,
          '($1) $2-$3',
        )
        this.licensePlate = formattedPhone

        const ddd = unformattedPhone.substring(0, 2)
        sessionStorage.setItem('ddd', ddd)

        const phoneNumberWithoutDdd = unformattedPhone.substring(2)
        sessionStorage.setItem('phone', phoneNumberWithoutDdd)
      }
    },
  },
})
</script>
