<template>
  <div class="main">
    <div
      v-if="loading"
      class="loading-overlay"
    >
      <div class="loading-gif"></div>
    </div>
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/recarga"><img :src="icBack"></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <p>Selecione o valor da recarga</p>
        <div class="info border">
          <span class="badge">Valores</span>

          <div class="list scroll">
            <div class="item border" v-for="(recarga, idx) in valoresRecarga" :key="idx">
      <input
        type="checkbox"
        :checked="selectedProductId === recarga.product_id"
        :id="'checkbox-' + idx"
        @click="setValorRecarga(recarga.amount, recarga.product_id)"
      >
      <label :for="'checkbox-' + idx">{{ recarga.name }}</label> 
      <small>{{ formatCurrency(recarga.amount) }}</small>
    </div>
          </div>

        </div>
        <router-link to="/recarga/info-recarga"><img
          :src="next"
          class="nextStep"
          :class="{ disabled: !isValorSelected }"
        ></router-link>
      </div>
    </div>

    <FooterGlobal></FooterGlobal>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';
import axios from 'axios';
// import axios from 'axios';

export default defineComponent({
  name: 'ParcelaBoleto',
  components: {
    FooterGlobal
  },
  data () {
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      valoresRecarga: [],
      loading: false,
      isValorSelected: false,
      selectedProductId: null,
    };
  },
  mounted() {
    this.listaValores();
  },
  methods: {
setValorRecarga(value, productId){
    this.selectedProductId = this.selectedProductId === productId ? null : productId;
    this.isValorSelected = this.selectedProductId !== null;
    if (this.isValorSelected) {
      sessionStorage.setItem('valorRecarga', value);
      sessionStorage.setItem('productId', productId);
    } else {
      sessionStorage.removeItem('valorRecarga');
      sessionStorage.removeItem('productId');
    }
  },
    async listaValores() {
      this.loading = true
      let link = sessionStorage.getItem('linkOperadora');
      let response = await axios.post(
        'https://appws.feelbank.com.br/cdx/totem/recargaListaValores',
        {
          "linkOperadora": link,
        },
        {
          headers: {
            'AdgToken': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
          }
        }
      )
      this.loading = false
      if (response.data.status)
        this.valoresRecarga = response.data.dados
    },
    formatCurrency(amountInCents) {
    // Converter centavos para reais
    const amountInReal = amountInCents / 100;

    // Use a função Intl.NumberFormat para formatar como moeda brasileira (BRL)
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });

    return formatter.format(amountInReal);
  },
  }
})
</script>