<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/home"><img :src="icBack"></router-link>
        <span>Sorte Online</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <p>Selecione uma opção de jogo abaixo</p>
        <div
          class="select-sorte"
          v-if="jogosDisponiveis && jogosDisponiveis.dados"
        >
          <div
            v-for="jogo in jogosDisponiveis.dados"
            :key="jogo.id"
            @click="selecionarJogo(jogo)"
            :class="{ 'box-normal': true, 'active': jogo.id === idJogo }"
          >
            <p>
              Tipo Loteria: {{ jogo.tipo }}
              <span>Prêmio: {{ formatarPremio(jogo.prize) }}</span>
            </p>
            <small v-html="jogo.description"></small>
            <p>Concurso: {{ jogo.concourse }} <span>Data: {{ formatarData(jogo.concourseDate) }}</span></p>
            <h6>Valor da aposta: R${{ jogo.price.toFixed(2) }}</h6>
          </div>
        </div>
        <router-link
          to="/sorte/dados-sorte"
          @click="avancarParaDadosSorte"
        ><img
            :src="next"
            class="nextStep"
            :class="{ disabled: !jogoSelecionado }"
            v-bind:disabled="!jogoSelecionado"
          ></router-link>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import icBack from '@/assets/svg/ic-back.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icNext from '@/assets/svg/ic-next.svg';
import FooterGlobal from '@/components/Footer.vue';
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'BoletoPage',
  components: {
    FooterGlobal,
  },
  data () {
    timerToReturnToHome(40000)
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      jogosDisponiveis: {},
      idJogo: null,
      priceJogo: 0,

      jogoSelecionado: false,
    };
  },

  mounted () {
    this.recuperaJogosSession();
  },


  methods: {
    formatarPremio (valor) {
      return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    formatarData (data) {
      const dataFormatada = new Date(data);
      return dataFormatada.toLocaleDateString('pt-BR');
    },
    selecionarJogo ({ id, price, concourse, name, prize, concourseDate }) {
      this.idJogo = id;
      this.concourse = concourse;
      this.concourseDate = concourseDate;
      this.priceJogo = price;
      this.name = name;
      this.prize = prize;
      this.jogoSelecionado = true; // Defina como true quando um jogo for selecionado
    },
    avancarParaDadosSorte () {
      if (this.idJogo !== null) {
        // Salvar o ID do jogo em sessionStorage
        sessionStorage.setItem('idJogo', this.idJogo);
        sessionStorage.setItem('priceJogo', this.priceJogo);
        sessionStorage.setItem('concourse', this.concourse);
        sessionStorage.setItem('concourseDate', this.concourseDate);
        sessionStorage.setItem('name', this.name);
        sessionStorage.setItem('prize', this.prize);
      } else {
        // Lidar com o caso em que nenhum jogo foi selecionado
      }
    },
    recuperaJogosSession () {
      this.jogosDisponiveis = JSON.parse(
        sessionStorage.getItem('jogosDisponiveis')
      );
    }
  }
})
</script>
