<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/pix"><img :src="icBack" /></router-link>
        <span>Pix Cap</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Precisamos de algumas informações</p>
        <input
          type="input"
          placeholder="CPF"
          v-model="valueCPF"
          @click="toggleKeyboard('CPF')"
          :class="{ disabled: activeInput === 'CPF' && isKeyboardOpen }"
        />
        <input
          type="input"
          placeholder="Telefone"
          v-model="valuePhone"
          @click="toggleKeyboard('Phone')"
          :class="{ disabled: activeInput === 'Phone' && isKeyboardOpen }"
        />
        <input
          type="input"
          placeholder="CEP"
          v-model="valueCEP"
          @click="toggleKeyboard('CEP')"
          :class="{ disabled: activeInput === 'CEP' && isKeyboardOpen }"
        />
        <input
          type="input"
          placeholder="Email"
          v-model="valueEmail"
          @click="toggleKeyboard('Email', true)"
          :class="{ disabled: activeInput === 'Email' && isKeyboardOpen }"
        />
        <virtual-keyboard
          v-if="isKeyboardOpen"
          :activeInput="activeInput"
          :fullKeyboard="fullKeyboard"
          @key-click="handleKeyClick"
          @close-keyboard="closeKeyboard"
        ></virtual-keyboard>
        <router-link to="/pix/dados-pix"
          ><img :src="next" class="nextStep"
        /></router-link>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// IMAGES
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue' // Import the Keyboard component
// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'

export default defineComponent({
  name: 'InfoPix',
  components: {
    FooterGlobal,
    VirtualKeyboard,
  },
  data() {
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      isKeyboardOpen: false,
      isInputDisabled: false,
      fullKeyboard: false,
      valueCPF: '',
      valuePhone: '',
      valueCEP: '',
      valueEmail: '',
      activeInput: null,
    }
  },
  methods: {
    handleKeyClick(key) {
      // A função agora verifica qual input está ativo para atualizar
      if (this.activeInput && key === 'backspace') {
        this[this.activeInput] = this[this.activeInput].slice(0, -1)
      } else if (this.activeInput) {
        this[this.activeInput] += key
      }
    },
    toggleKeyboard(inputType, fullKeyboard = false) {
      this.isKeyboardOpen = !this.isKeyboardOpen
      this.fullKeyboard = fullKeyboard
      this.activeInput = this.isKeyboardOpen ? inputType : null
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
      this.activeInput = null // Reseta o input ativo quando o teclado é fechado
      this.isInputDisabled = false
    },
  },
})
</script>
