<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/home"><img :src="icBack" /></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <div class="errorMessage">
          <img :src="icAttention" />
          <h2>Recarga não autorizada!</h2>
          <p>
            Você será redirecionado para página principal em alguns segundos...
          </p>
        </div>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
// Importe os módulos necessários
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import icAttention from '@/assets/svg/ic-attention.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next.svg'
import FooterGlobal from '@/components/Footer.vue'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'BoletoPage',
  components: {
    FooterGlobal,
  },
  data() {
    timerToReturnToHome(40000)

    return {
      icBack: icBack,
      icClose: icClose,
      icAttention: icAttention,
      next: icNext,
      isKeyboardOpen: false,
      isInputDisabled: false,
      linhaDigitavel: '',
      codeBar: '',
      loading: false,
      router: null,
    }
  },
  methods: {},
})
</script>
