import axios from "axios";

const api = axios.create({
    baseURL: 'https://appws.feelbank.com.br/cdx/totem/',
});

api.interceptors.request.use(
    async (config) => {
        try {
            config.headers['AdgToken'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8';
            config.headers['Content-Type'] = 'application/json';

            return config;
        } catch (error) {
            return Promise.reject(error)
        }
    },
    (error) => {
        return Promise.reject(error)
    }
);

export default api;