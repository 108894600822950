<template>
  <div class="main">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-gif"></div>
    </div>
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/home"><img :src="icBack" /></router-link>
        <span>Boletos</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>
          Informe a linha digitável ou utilize o leitor de código de barras.
        </p>
        <input
          id="typeful"
          type="input"
          placeholder="Linha Digitavel"
          v-model="linhaDigitavel"
          @click="toggleKeyboard"
          :class="{ disabled: isInputDisabled }"
        />
        <virtual-keyboard
          v-if="isKeyboardOpen"
          @key-click="handleKeyClick"
          @close-keyboard="closeKeyboard"
          @delete-click="handleDeleteClick"
        ></virtual-keyboard>
        <a href="#" @click.prevent="consultaBoleto">
    <img :src="next" class="nextStep" :class="{ disabled: !isCodeBarFilled }" />
  </a>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
// Importe os módulos necessários
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next.svg'
import FooterGlobal from '@/components/Footer.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import axios from 'axios'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'BoletoPage',
  components: {
    FooterGlobal,
    VirtualKeyboard,
  },
  data() {
    timerToReturnToHome(40000)
    onMounted(() => {
      this.router = useRouter()
      let el = document.getElementById('typeful')
      el.focus()
      this.toggleKeyboard()
    })

    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      isKeyboardOpen: false,
      isInputDisabled: false,
      linhaDigitavel: '',
      codeBar: '',
      loading: false,
      router: null,
    }
  },
  computed: {
    isCodeBarFilled() {
      return (
        (this.linhaDigitavel.replaceAll(' ', '').replaceAll('.', '').length === 44) ||
        (this.linhaDigitavel.replaceAll(' ', '').replaceAll('.', '').length === 47)
      );
    },
  },
  methods: {
    handleKeyClick(key) {
      if (key === 'backspace') {
        this.linhaDigitavel = this.linhaDigitavel.slice(0, -1)
      } else {
        this.linhaDigitavel += key
      }
    },
    handleDeleteClick() {
      if (this.linhaDigitavel.length > 0) {
        this.linhaDigitavel = this.linhaDigitavel.slice(0, -1)
      }
    },
    toggleKeyboard() {
      this.isKeyboardOpen = !this.isKeyboardOpen
      this.isInputDisabled = this.isKeyboardOpen
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
      this.isInputDisabled = false
    },
    async consultaBoleto() {
      this.loading = true
      let response

      try {
        response = await axios.post(
          'https://appws.feelbank.com.br/cdx/totem/boletoPagamentoConsulta.php',
          {
            codigoBarras:
              this.linhaDigitavel.replaceAll(' ', '').replaceAll('.', '')
                .length === 44
                ? this.linhaDigitavel.replaceAll(' ', '').replaceAll('.', '')
                : '',
            linhaDigitavel:
              this.linhaDigitavel.replaceAll(' ', '').replaceAll('.', '')
                .length === 47
                ? this.linhaDigitavel.replaceAll(' ', '').replaceAll('.', '')
                : '',
          },
          {
            headers: {
              AdgToken:
                'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
            },
          },
        )
      } catch (error) {
        this.loading = false
        if (error.response && error.response.status === 400) {
          const router = useRouter()
          router.push('/erro')
        }
        return
      }
      
      this.loading = false
      if (response.data.status === false && response.data.HTTPCODE === 400) {
        if (this.router) {
          this.router.push('/erro')
        }
        return
      }

      if (response.data.dados) {
        sessionStorage.setItem(
          'dadosBoleto',
          JSON.stringify(response.data.dados),
        )
        this.$router.push('/boleto/info-boleto')
      }
    },
  },
})
</script>
