<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <!-- <router-link to="/boleto/parcela-boleto"><img :src="icBack"></router-link> -->
        <p></p>
        <span>Boletos</span>
        <p></p>
        <!-- <router-link to="/home"><img :src="icClose"></router-link> -->
      </div>
      <div class="content">
        <div class="center">
          <div class="icon">
            <div class="inner-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 756 858" enable-background="new 0 0 756 858" xml:space="preserve">
                    <path id="bottomslide" fill="#00C992" d="M495.8,541.9h-84.5c-1.1,0-2-0.9-2-2V342.5c0-1.1,0.9-2,2-2h84.5c1.1,0,2,0.9,2,2v197.5
	C497.8,541,496.9,541.9,495.8,541.9z"></path>
                    <g id="card" v-bind:class="{'animate':step === 'stepfive'}">
                        <path fill="#E5E5E5" d="M556,123.2v199.9c0,1.1-0.9,2-2,2H430.7c-1.1,0-2-0.9-2-2V123.2c0-1.1,0.9-2,2-2H554
		C555.1,121.2,556,122.1,556,123.2z"></path>

                        <rect x="351.2" y="209.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 230.0159 676.2959)" fill="#303030" width="203.9" height="27.2"></rect>

                        <rect x="430.5" y="213.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 274.1384 720.4184)" fill="#FFFFFF" width="133.5" height="18.5"></rect>

                        <rect x="484.3" y="171.1" transform="matrix(6.123234e-17 -1 1 6.123234e-17 323.1085 671.4483)" fill="#A0A0A0" width="26" height="6.2"></rect>

                        <rect x="528.8" y="288.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 250.194 833.3431)" fill="#A0A0A0" width="26" height="6.2"></rect>

                        <rect x="501.6" y="226.7" transform="matrix(6.123234e-17 -1 1 6.123234e-17 311.9859 771.5512)" fill="#A0A0A0" width="80.3" height="6.2"></rect>
                    </g>
                    <g id="machine">
                        <path id="topslide" fill="#008964" d="M483.6,546.2h-84.5c-1.1,0-2-0.9-2-2v-206c0-1.1,0.9-2,2-2h84.5c1.1,0,2,0.9,2,2v206
		C485.6,545.3,484.7,546.2,483.6,546.2z"></path>
                        <path fill="#5ED3CA" d="M426.6,794.8h-304c-1.1,0-2-0.9-2-2V316.7c0-1.1,0.9-2,2-2h304c1.1,0,2,0.9,2,2v476.1
		C428.6,793.9,427.7,794.8,426.6,794.8z"></path>
                        <path fill="#00664A" d="M422.6,786.2H126c-3.3,0-6-2.7-6-6V271.5c0-3.3,2.7-6,6-6h296.6c3.3,0,6,2.7,6,6v508.8
		C428.6,783.5,425.9,786.2,422.6,786.2z"></path>
                        <rect x="120" y="295.5" fill="#00664A" width="308.6" height="495.1"></rect>
                        <rect x="146.8" y="295.5" fill="#009D72" width="255" height="499.3"></rect>
                        <rect x="171.4" y="325.5" fill="#F9F9F9" width="205.7" height="158.6"></rect>
                        <g>
                            <g>
                                <path fill="#008964" d="M229.4,537.6H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,536.7,230.5,537.6,229.4,537.6z"></path>
                                <path fill="#008964" d="M302.6,537.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,536.7,303.7,537.6,302.6,537.6z"></path>
                                <path fill="#008964" d="M375.8,537.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,536.7,376.9,537.6,375.8,537.6z"></path>
                            </g>
                            <g>
                                <path fill="#008964" d="M229.4,582.6H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,581.7,230.5,582.6,229.4,582.6z"></path>
                                <path fill="#008964" d="M302.6,582.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,581.7,303.7,582.6,302.6,582.6z"></path>
                                <path fill="#008964" d="M375.8,582.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,581.7,376.9,582.6,375.8,582.6z"></path>
                            </g>
                            <g>
                                <path fill="#008964" d="M229.4,627.6H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,626.7,230.5,627.6,229.4,627.6z"></path>
                                <path fill="#008964" d="M302.6,627.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,626.7,303.7,627.6,302.6,627.6z"></path>
                                <path fill="#008964" d="M375.8,627.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,626.7,376.9,627.6,375.8,627.6z"></path>
                            </g>
                            <g>
                                <path fill="#008964" d="M229.4,672.6H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,671.7,230.5,672.6,229.4,672.6z"></path>
                                <path fill="#008964" d="M302.6,672.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,671.7,303.7,672.6,302.6,672.6z"></path>
                                <path fill="#008964" d="M375.8,672.6h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,671.7,376.9,672.6,375.8,672.6z"></path>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#fff" d="M229.4,531.2H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,530.3,230.5,531.2,229.4,531.2z"></path>
                                <path fill="#fff" d="M302.6,531.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,530.3,303.7,531.2,302.6,531.2z"></path>
                                <path fill="#fff" d="M375.8,531.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,530.3,376.9,531.2,375.8,531.2z"></path>
                            </g>
                            <g>
                                <path fill="#fff" d="M229.4,576.2H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,575.3,230.5,576.2,229.4,576.2z"></path>
                                <path fill="#fff" d="M302.6,576.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,575.3,303.7,576.2,302.6,576.2z"></path>
                                <path fill="#fff" d="M375.8,576.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,575.3,376.9,576.2,375.8,576.2z"></path>
                            </g>
                            <g>
                                <path fill="#fff" d="M229.4,621.2H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,620.3,230.5,621.2,229.4,621.2z"></path>
                                <path fill="#fff" d="M302.6,621.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,620.3,303.7,621.2,302.6,621.2z"></path>
                                <path fill="#fff" d="M375.8,621.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,620.3,376.9,621.2,375.8,621.2z"></path>
                            </g>
                            <g>
                                <path fill="#fff" d="M229.4,666.2H174c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C231.4,665.3,230.5,666.2,229.4,666.2z"></path>
                                <path fill="#fff" d="M302.6,666.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C304.6,665.3,303.7,666.2,302.6,666.2z"></path>
                                <path fill="#fff" d="M375.8,666.2h-55.3c-1.1,0-2-0.9-2-2v-21.7c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v21.7
				C377.8,665.3,376.9,666.2,375.8,666.2z"></path>
                            </g>
                        </g>
                        <g>
                            <path fill="#008964" d="M229.4,743.4H174c-1.1,0-2-0.9-2-2v-28.1c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v28.1
			C231.4,742.5,230.5,743.4,229.4,743.4z"></path>
                            <path fill="#008964" d="M302.6,743.4h-55.3c-1.1,0-2-0.9-2-2v-28.1c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v28.1
			C304.6,742.5,303.7,743.4,302.6,743.4z"></path>
                            <path fill="#008964" d="M375.8,743.4h-55.3c-1.1,0-2-0.9-2-2v-28.1c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v28.1
			C377.8,742.5,376.9,743.4,375.8,743.4z"></path>
                        </g>
                        <g>
                            <path fill="#FFFFFF" d="M229.4,736.9H174c-1.1,0-2-0.9-2-2v-28.1c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v28.1
			C231.4,736,230.5,736.9,229.4,736.9z"></path>
                            <path fill="#FFFFFF" d="M302.6,736.9h-55.3c-1.1,0-2-0.9-2-2v-28.1c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v28.1
			C304.6,736,303.7,736.9,302.6,736.9z"></path>
                            <path fill="#FFFFFF" d="M375.8,736.9h-55.3c-1.1,0-2-0.9-2-2v-28.1c0-1.1,0.9-2,2-2h55.3c1.1,0,2,0.9,2,2v28.1
			C377.8,736,376.9,736.9,375.8,736.9z"></path>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
          <p>Para efetuar o pagamento, siga as instruções na maquininha.</p>
        </div>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue';
// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';
import { saveTransaction } from '@/pinpad/boleto';
import { useRouter } from 'vue-router'
import { openSupportTask } from '@/observability/observability'
import axios from 'axios'
import { pinpadUrl, pinpadAuth } from '@/helper/vars';



export default defineComponent({
  name: 'PagamentoBoletoCartao',
  components: {
    FooterGlobal
  },
  data () {
    onMounted(async () => {
      const router = useRouter()
      
      const installmentSelect = JSON.parse(sessionStorage.getItem('installmentSelect'))
      const dadosBoleto = JSON.parse(sessionStorage.getItem('dadosBoleto'));
      const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'));
  
      const payload = {
        service: "Boleto",
        type: "credit-card",
        amount: parseInt(installmentSelect.totalParcela * 100),
        installments: parseInt(installmentSelect.installments),
        timeout: 90,
        // mock: true
      }

      var data = JSON.stringify(payload)
      var config = {
        method: 'post',
        url: pinpadUrl,
        headers: {
          'Authorization': pinpadAuth,
          'Content-Type': 'application/json'
        },
        data: data
      }
      let purchase = {}
      await axios(config)
        .then(async function (response) {
          purchase = response.data
          if (purchase.status === 'timeout') {
            router.push('/home')
          } else {
            if (purchase.status === 'approved') {
              // Verifica se o status é 'approved' antes de chamar a API cadastrarSorteOnline
              await saveTransaction(purchase)
                .then(response => {
                  purchase.orderNumber = response.data.id
                })
                .catch(error => {
                  openSupportTask('Erro ao gravar transação no portal', error)
                  router.push('/boleto/pagamento-boleto-rejeitado')
                })
            }
          }
          return response.data
        })
        .catch(function (error) {
          openSupportTask('Erro ao efetuar pagamento no Pinpad', error)
        })
      this.$store.dispatch('setReceipt', purchase)

      if (purchase.status == 'approved') {
        router.push('/boleto/pagamento-boleto-sucesso')
      } else if (purchase.status == 'rejected') {
        router.push('/boleto/pagamento-boleto-rejeitado')
      } else {
        router.push('/home')
        return
      }

      const payloadBoleto = {
        idMovimentacao: purchase.orderNumber,
        idConsulta: dadosBoleto.id,
        linhaDigitavel: "",
        codigoBarras: dadosBoleto.barCode,
        nome: dadosBoleto.payer.name,
        documento: dadosPessoais.cpf,
        telefone: dadosPessoais.phone,
        valorOriginal: dadosBoleto.originalAmount, 
        valorTotal: parseInt(installmentSelect.totalParcela * 100),
        valorJuros: dadosBoleto.interest,
        valorMulta: dadosBoleto.fine,
        valorDesconto: dadosBoleto.discount,
        vencimento: dadosBoleto.dueDate
      };

      try {
        if (purchase.status === 'approved') {
          const response = await axios.post('https://appws.feelbank.com.br/cdx/totem/cadastrarBoleto', payloadBoleto, {
            headers: {
              'AdgToken': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxMTI4IiwibG9naW4iOiJ0aUBmaXJzdHBhZy5jb20uYnIiLCJlbWFpbCI6InRpQGZpcnN0cGFnLmNvbS5iciJ9.CVb4rWw9Jofht8fFcRtG3hIKYW6_hP7ja3-4toaRQp8',
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            console.log('Boleto registrado com sucesso.');
          } else {
            console.log('Falha ao registrar boleto. Resposta:', response.data);
          }
        }
      } catch (error) {
        console.error('Erro ao enviar ao boleto:', error);
      }
    });
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      selectedOptions: [],
    };
  },
  methods: {

  },
})
</script>