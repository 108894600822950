<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/boleto/parcela-boleto"
          ><img :src="icBack"
        /></router-link>
        <span>Boletos</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <div class="center">
          <p>Para efetuar o pagamento, siga as instruções na maquininha.</p>
        </div>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// IMAGES
import icNext from '@/assets/svg/ic-next.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icBack from '@/assets/svg/ic-back.svg'

// COMPONENTS
import FooterGlobal from '@/components/Footer.vue'

export default defineComponent({
  name: 'PagamentoPixCartao',
  components: {
    FooterGlobal,
  },
  data() {
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      selectedOptions: [],
    }
  },
  methods: {
    handleCheckboxChange(id) {
      // Lógica para redirecionar para rotas específicas
      if (id === 'pix') {
        // Redirecionar para a rota específica para PIX
        this.$router.push('/pagamento-debitos-pix')
      } else if (id === 'credito') {
        // Redirecionar para a rota específica para crédito
        this.$router.push('/pagamento-debitos-cartao')
      }
      // Pode adicionar mais lógica para outras opções, se necessário
    },
    handleNextStepClick() {
      // Lógica para redirecionar para outra rota ao clicar em nextStep
      this.$router.push('/outra-rota')
    },
  },
})
</script>
