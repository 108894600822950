<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito"><img :src="icBack"></router-link>
        <span>Débito Veicular</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <p>Olá,
          <small>Consulte abaixo e selecione os seus débitos veiculares que gostaria de pagar.</small>
        </p>
        <div class="info">
          <span class="badge">Débitos</span>
          <div
            class="list"
            v-if="debitos.length"
          >
            <div class="scroll">
              <div
                v-for="debito in debitos"
                :key="debito.id"
                class="item"
              >
                <input
                  type="checkbox"
                  :checked="debito.selected"
                  :disabled="debito.disabled"
                  @click="toggleDebito(debito)"
                >

                <label>{{ debito.descricao }}</label>
                <small>R$ {{ debito.valor.toFixed(2) }}</small>
              </div>
            </div>
            <div class="total">
              <p>Total: <small>R$ {{ totalDebitosFormatted }}</small></p>
            </div>
          </div>
          <div
            v-if="debitos.length === 0"
            class="warning"
          >
            <p>
              <small>Você não possuí débitos pendentes.</small>
            </p>
          </div>
        </div>
        <router-link
    to="/debito/dados-debito-veicular"
    v-if="debitos.length"
    class="router-link"
  >
    <img
      :src="next"
      class="nextStep"
      :class="{ disabled: !isAnyDebitoSelected }" 
      @click.prevent="isAnyDebitoSelected && goToNextStep()"
    >
  </router-link>
      </div>

      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

// IMAGES
import icNext from '@/assets/svg/ic-next.svg';
import icClose from '@/assets/svg/ic-close.svg';
import icBack from '@/assets/svg/ic-back.svg';


// COMPONENTS
import FooterGlobal from '@/components/Footer.vue';

import { timerToReturnToHome } from '../../helper/timer'


export default defineComponent({
  name: 'SelecaoDebitoVeicular',
  components: {
    FooterGlobal
  },
  data () {
    timerToReturnToHome(40000)
    return {
      next: icNext,
      icClose: icClose,
      icBack: icBack,
      debitos: [],
      totalDebitos: 0
    };
  },
  computed: {
    totalDebitosFormatted() {
      return this.totalDebitos.toFixed(2);
    },
    isAnyDebitoSelected() {
      return this.debitos.some(debito => debito.selected);
    }
  },
  created () {
    this.fetchDebitos();
  },
  methods: {
    toggleDebito (debito) {
      debito.selected = !debito.selected;
      if (debito.dependentes && debito.dependentes.length > 0) {
        debito.dependentes.forEach((dep) => {
          this.debitos.forEach(d => {
            if (dep == d.codigoDependencia) {
              d.selected = debito.selected;
              d.disabled = debito.selected;
            }
          })
        })
      }

      this.totalDebitos = this.debitos.reduce((total, deb) => {
        return deb.selected ? total + parseFloat(deb.valor) : total;
      }, 0);

      const debitosSelecionados = this.debitos.filter(deb => deb.selected);
      sessionStorage.setItem('debitosSelecionados', JSON.stringify(debitosSelecionados));

      sessionStorage.setItem('totalDebito', JSON.stringify({ totalDebitos: this.totalDebitos.toFixed(2) }));
    },

    fetchDebitos () {
      const consulta = JSON.parse(sessionStorage.getItem('dadosConsultaPlaca'));
      //console.log(consulta.dataResult.veiculo.uf);

      if (consulta && consulta.dataResult && consulta.dataResult.debitos) {
        this.debitos = consulta.dataResult.debitos.map(debito => ({
          ...debito,
          selected: false,
          disabled: false
        }));
        this.totalDebitos = 0;

        if (consulta.dataResult.clientes && consulta.dataResult.clientes.length > 0) {
          const clienteNome = consulta.dataResult.clientes[0].nome;
          sessionStorage.setItem('clienteNome', clienteNome);
        }

        // Adicionado novo if para armazenar UF do veículo em sessionStorage
        if (consulta.dataResult.veiculo && consulta.dataResult.veiculo.uf.length > 0) {
          const clienteVeiculo = consulta.dataResult.veiculo.uf;
          sessionStorage.setItem('clienteVeiculo', clienteVeiculo);
        }
      }
    },
    goToNextStep() {
      this.$router.push('/debito/dados-debito-veicular');
    }

  }
});

</script>
