<template>
  <div class="main bgSucesso">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/debito/dados-debito-veicular"
          ><img :src="icBack"
        /></router-link>
        <span class="text-white">Débito Veicular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <div class="o-circle c-container__circle o-circle__sign--success">
          <div class="o-circle__sign"></div>
        </div>
        <h1>
          Pagamento efetuado <br />
          com sucesso!!
        </h1>
        <p class="white">
          {{ formatAmount(purchase.totalAmount) }} <br /><br />{{
            titleCase(dateFormatted[0])
          }}<br />
          {{ dateFormatted[1] }} {{ titleCase(dateFormatted[2]) }} <br />
          {{ dateFormatted[3] }}
          <br /><br />
          Nº do pedido: {{ purchase.orderNumber }}
        </p>
        <button @click="enviarParaAPI" class="success">
          Gerar comprovante
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { intToRealBRL } from '@/helper/coin'
import icClose from '@/assets/svg/ic-close-white.svg'
import icSuccess from '@/assets/svg/success.svg'
import { timerToReturnToHome } from '@/helper/timer'
import axios from 'axios'

export default defineComponent({
  name: 'PagamentoDebitoVeicularSucesso',
  components: {},
  methods: {
    formatAmount(amount) {
      return intToRealBRL(amount)
    },
    titleCase(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
    },
    enviarParaAPI() {
      const purchase = this.$store.getters.receipt
      const formattedDate = format(new Date(), 'dd/MM/yy HH:mm:ss')
      const formattedValue = intToRealBRL(purchase.originalAmount)
      const clienteVeiculo = sessionStorage.getItem('clienteVeiculo')
      const placaDigitada = sessionStorage.getItem('placaDigitada')
      const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'))
      const creditoParcela = sessionStorage.getItem('selectedInstallmentValue')

      const receiptDetails = `
  CDX EXPRESS
  CNPJ: 28.707.687/0001-19
  CDX*EXPRESS
  ------------------------------------------------------------------------------
  DATA: ${formattedDate}
  AUT: ${purchase.authorizationCode}  DOC: ${purchase.document}
  EC: ${purchase.mcc} TERM: ${purchase.serialNumber}
  ${purchase.cardBrand} ${purchase.cardNumber}
  ${creditoParcela || ''}
  VALOR: ${formattedValue}
  ------------------------------------------------------------------------------
  TRANSAÇÃO APROVADA 
  MEDIANTE USO DE SENHA PESSOAL
  DEBITO VEICULAR
  ------------------------------------------------------------------------------
  CONFIRA SEUS DADOS
  CPF: ${dadosPessoais.cpf || ''}
  FONE: ${dadosPessoais.phone || ''}
  PLACA: ${placaDigitada || ''}
  ESTADO: ${clienteVeiculo || ''}
  ------------------------------------------------------------------------------
  EM CASO DE DUVIDAS 
  ENTRE EM CONTATO (11) 3541-3001
  `
      const dataToSend = {
        Param: '',
        Data: '',
        ModeloImpressora: 'Default',
        Documents: [receiptDetails],
      }

      axios
        .post('http://localhost:5001/api/print', dataToSend)
        .then((response) => {
          console.log('Resposta da API:', response.data)
        })
        .catch((error) => {
      console.error('Erro ao enviar para a API:', error);
      // Exibir um alerta de erro genérico
      alert('Impressora desligada, por favor, tente novamente.');
    });
    },
  },
  data() {
    timerToReturnToHome(30000)
    const date = new Date()
    let dateFormatted = format(
      date,
      "EEEE,'|'d 'de' |MMMM 'de' yyyy '| às' HH'h'mm",
      { locale: ptBR },
    ).split('|')
    let purchase = this.$store.getters.receipt
    return {
      icClose: icClose,
      icSuccess: icSuccess,
      purchase,
      dateFormatted,
    }
  },
})
</script>
