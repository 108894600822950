<template>
  <div class="main bgRejeitado">
    <div class="wrapper">
      <div class="navigation">
        <p></p>
        <span class="text-white">Boleto</span>
        <p></p>
      </div>
      <div class="content">
        <div class="o-circle c-container__circle o-circle__sign--failure">
          <div class="o-circle__sign"></div>
        </div>
        <h1>Infelizmente o seu pagamento não foi efetuado.</h1>
        <p class="danger">
          Se tiver alguma dúvida, entre em contato conosco: (11) 3003-4996 ou
          aponte a câmera do celular para o QRCode abaixo:
        </p>
        <div v-if="qrCodeData">
          <qrcode :value="qrCodeData" class="successIcon" />
        </div>
        <router-link to="/home"
          ><button class="danger">Voltar</button></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import icClose from '@/assets/svg/ic-close-white.svg'
import { timerToReturnToHome } from '@/helper/timer'
import VueQRCodeComponent from 'vue-qrcode'
import { intToRealBRL } from '@/helper/coin'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import axios from 'axios'

export default defineComponent({
  name: 'PagamentoBoletoRejeitado',
  components: {
    qrcode: VueQRCodeComponent,
  },
  methods: {
    formatAmount(amount) {
      return intToRealBRL(amount)
    },
    titleCase(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
    },
    enviarParaAPI() {
      const purchase = this.$store.getters.receipt
      const formattedDate = format(new Date(), 'dd/MM/yy HH:mm:ss')
      const formattedValue = intToRealBRL(purchase.originalAmount)
      const nome = sessionStorage.getItem('nome')
      const cpfCnpj = sessionStorage.getItem('cpfCnpj')
      const valorBoleto = sessionStorage.getItem('valorBoleto')
      const creditoParcela = sessionStorage.getItem('selectedInstallmentValue')

      const receiptDetails = `
  CDX EXPRESS
  CNPJ: 28.707.687/0001-19
  CDX*EXPRESS
  ------------------------------------------------------------------------------
  DATA: ${formattedDate}
  AUT: ${purchase.authorizationCode}  DOC: ${purchase.document}
  EC: ${purchase.mcc} TERM: ${purchase.serialNumber}
  ${purchase.cardBrand} ${purchase.cardNumber}
  ${creditoParcela || ''}
  VALOR: ${formattedValue}
  ------------------------------------------------------------------------------
  TRANSAÇÃO APROVADA 
  MEDIANTE USO DE SENHA PESSOAL
  BOLETO ONLINE
  ------------------------------------------------------------------------------
  CONFIRA OS DADOS DO BOLETO
  NOME: ${nome || ''}
  CNPJ: ${cpfCnpj || ''}
  VALOR: ${valorBoleto || ''}
  ------------------------------------------------------------------------------
  EM CASO DE DUVIDAS 
  ENTRE EM CONTATO (11) 3541-3001
  ------------------------------------------------------------------------------
  `
      const dataToSend = {
        Param: '',
        Data: '',
        ModeloImpressora: 'Default',
        Documents: [receiptDetails],
      }

      axios
        .post('http://localhost:5001/api/print', dataToSend)
        .then((response) => {
          console.log('Resposta da API:', response.data)
        })
        .catch((error) => {
          console.error('Erro ao enviar para a API:', error)
        })
    },
  },
  data() {
    timerToReturnToHome(40000)
    const date = new Date()
    let dateFormatted = format(
      date,
      "EEEE,'|'d 'de' |MMMM 'de' yyyy '| às' HH'h'mm",
      { locale: ptBR },
    ).split('|')
    let purchase = this.$store.getters.receipt
    let msg =
      'Olá, estou com um problema com o pagamento em um Totem HPAY, poderia me ajudar? Número do pedido: ' +
      purchase.orderNumber
    let qrCodeData = 'https://wa.me/1130034996?text=' + msg
    return {
      icClose: icClose,
      qrCodeData,
      purchase,
      dateFormatted,
    }
  },
})
</script>
