<template>
  <div class="main" @click="pageClick">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-gif"></div>
    </div>
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/home"><img :src="icBack" /></router-link>
        <span>Débito Veicular</span>
        <router-link to="/home"><img :src="icClose" /></router-link>
      </div>
      <div class="content">
        <p>Parcele aqui seus débitos veiculares em até 18x!</p>
        <input
          type="text"
          placeholder="Digite a placa"
          v-model="licensePlate"
          @click="toggleKeyboard"
          :class="{ disabled: isInputDisabled }"
        />
        <div class="error" v-if="!success">
          Erro na busca cheque se a placa está correta e tente novamente.
        </div>
        <virtual-keyboard
          v-if="isKeyboardOpen"
          @key-click="handleKeyClick"
          @close-keyboard="closeKeyboard"
          @delete-click="handleDeleteClick"
        ></virtual-keyboard>
        <a @click="avançar()">
          <img
            :src="next"
            class="nextStep"
            :class="{ disabled: !isLicensePlateValid }"
          />
        </a>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import icBack from '@/assets/svg/ic-back.svg'
import icClose from '@/assets/svg/ic-close.svg'
import icNext from '@/assets/svg/ic-next.svg'
import FooterGlobal from '@/components/Footer.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import axios from 'axios'
import { timerToReturnToHome } from '@/helper/timer'

export default defineComponent({
  name: 'DebitoPage',
  components: {
    FooterGlobal,
    VirtualKeyboard,
  },
  data() {
    timerToReturnToHome(40000)
    return {
      icBack: icBack,
      icClose: icClose,
      next: icNext,
      licensePlate: '',
      isKeyboardOpen: false,
      isInputDisabled: false,
      placa: '',
      veiculo: {
        uf: '',
      },
      loading: false,
      estado: '',
      codParceiro: 1,
      taxas: null,
      success: true,
    }
  },
  computed: {
    isLicensePlateValid() {
      return this.licensePlate.length === 7
    },
  },
  methods: {
    handleKeyClick(key) {
      if (key === 'backspace') {
        this.licensePlate = this.licensePlate.slice(0, -1)
      } else {
        this.licensePlate += key
      }
      this.licensePlate = this.licensePlate.trim() // Remove espaços extras, se houver
    },
    handleDeleteClick() {
      if (this.licensePlate.length > 0) {
        this.licensePlate = this.licensePlate.slice(0, -1)
      }
    },
    toggleKeyboard() {
      this.isKeyboardOpen = !this.isKeyboardOpen
      this.isInputDisabled = this.isKeyboardOpen
    },
    closeKeyboard() {
      this.isKeyboardOpen = false
      this.isInputDisabled = false
    },
    async avançar() {
      this.licensePlate = this.licensePlate.trim().toUpperCase() // Adicione esta linha aqui
      this.loading = true
      try {
        await this.consultarPlaca()
        sessionStorage.setItem('placaDigitada', this.licensePlate)
      } catch (error) {
        console.error('Erro ao tentar avançar:', error)
      } finally {
        this.loading = false
      }
    },

    async consultarPlaca() {
      const url =
        'https://cdx-integration.azurewebsites.net/api/Veiculo/' +
        this.licensePlate +
        '/0'

      const response = await axios.get(url, {
        headers: {
          usuario: 'cdxshared',
          token: '4C652C5B4DFA51C8D95A789AE9696',
        },
      })

      if (response.status !== 200) {
        throw new Error('Erro na lista de débitos')
      }
      this.success = response.data.success
      if (response.data.success) {
        await sessionStorage.setItem(
          'dadosConsultaPlaca',
          JSON.stringify(response.data),
        )
        this.$router.push('/debito/selecao-debito-veicular')
      }
    },
  },
})
</script>
