<template>
  <div class="main">
    <div class="wrapper">
      <div class="navigation">
        <router-link to="/recarga/valor-recarga"><img :src="icBack"></router-link>
        <span>Recarga de Celular</span>
        <router-link to="/home"><img :src="icClose"></router-link>
      </div>
      <div class="content">
        <p>Confirme os dados abaixo:</p>
        <div class="info borderPadding">
          <p><b>Valor:</b>
            <br>
            {{ formatCurrency(valorRecarga) }}
            <br><br>
            <b>Número:</b>
            <br>
            {{formattedPhone}}
            <br><br>
            <b>Operadora:</b>
            <br>
            {{nomeOperadora }} 
          </p>
        </div>
        <router-link to="/recarga/dados-recarga"><img
            :src="next"
            class="nextStep"
          ></router-link>
      </div>
      <FooterGlobal></FooterGlobal>
    </div>
  </div>
</template>
  
  <script>
import { defineComponent } from 'vue';
import icNext from '@/assets/svg/ic-next.svg';
import icBack from '@/assets/svg/ic-back.svg';
import icClose from '@/assets/svg/ic-close.svg';
import FooterGlobal from '@/components/Footer.vue';

export default defineComponent({
  name: 'DadosBoleto',
  components: {
    FooterGlobal,
  },
  data () {
    return {
      next: icNext,
      icBack: icBack,
      icClose: icClose,
      formattedCPF: '',
      formattedPhone: '',
      phoneRecarga: '',
      valorRecarga: '',
      nomeOperadora: '',
    };
  },
  mounted() {
  // Recupere o DDD e o número do telefone separadamente da sessionStorage
  const ddd = sessionStorage.getItem('ddd');
  const phone = sessionStorage.getItem('phoneRecarga');
  this.valorRecarga = sessionStorage.getItem('valorRecarga');

  // Agora combine o DDD e o número do telefone e formate
  this.formattedPhone = `(${ddd}) ${phone.substring(0,5)}-${phone.substring(5)}`;

  let inputString = sessionStorage.getItem('linkOperadora');
  let match = inputString.match(/provider=([^&]+)/);

  if (match) {
    this.nomeOperadora = match[1];
  }
},
  methods: {
    formatPhone () {
      const unformattedPhone = this.formattedPhone.replace(/\D/g, ''); // Remove non-numeric characters
      if (unformattedPhone.length <= 11) {
        const phoneGroups = unformattedPhone.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);
        if (phoneGroups) {
          const formattedPhone = phoneGroups
            .slice(1)
            .filter(group => group !== '')
            .map((group, index) => {
              if (index === 0) return `(${group})`;
              if (index === 1) return `${group} `;
              return group;
            })
            .join('');
          this.formattedPhone = formattedPhone;
        }
      }
    },
    formatCurrency(amountInCents) {
    // Converter centavos para reais
    const amountInReal = amountInCents / 100;

    // Use a função Intl.NumberFormat para formatar como moeda brasileira (BRL)
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });

    return formatter.format(amountInReal);
  },
  }
})
  </script>
